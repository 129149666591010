<template>
    <div>
        <restaurant-form :entity-id="entityId"></restaurant-form>
    </div>
</template>

<script>
import RestaurantForm from '@/components/pages/restaurants/Form'
import dirtyForm from '@/components/mixins/dirtyForm'
import mealType from '@/store/type/mealType'

export default {
    components: {
        RestaurantForm,
    },
    mixins: [dirtyForm],

    data() {
        return {
            entityId: null,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.entityId = route.params.id
            },
        },
    },

    beforeRouteLeave(to, from, next) {
        this.$store.dispatch(mealType.actions.CLEAR_ALL)
        next()
    },
}
</script>
