<template>
    <!-- PICKUP DETAILS TAB -->
    <v-tab-item
        key="pickup_details_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel
            v-if="model.pickupInformation"
            :title="$t('labels.pickup_details')"
        >
            <address-inputs
                v-model="model.pickupInformation"
                v-tab-error:pickupDetails
                name="pickupAddressInputs"
                zip-code-prop="postCode"
                town-prop="city"
                clearable
                :error-messages="addressErrors"
                :required="requirement"
                @clear="clear"
            ></address-inputs>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="model.pickupInformation.contactFirstName"
                        v-validate.immediate="
                            requirement
                                ? 'required|min:2|max:255|space'
                                : 'min:2|max:255|space'
                        "
                        v-tab-error:pickupDetails
                        name="pickupInformation.contactFirstName"
                        label="labels.first_name"
                        counter="255"
                        :error-messages="
                            errors.collect('pickupInformation.contactFirstName')
                        "
                        :data-vv-as="$t('labels.first_name')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="model.pickupInformation.contactLastName"
                        v-validate.immediate="
                            requirement
                                ? 'required|min:2|max:255|space'
                                : 'min:2|max:255|space'
                        "
                        v-tab-error:pickupDetails
                        name="pickupInformation.contactLastName"
                        label="labels.last_name"
                        counter="255"
                        :error-messages="
                            errors.collect('pickupInformation.contactLastName')
                        "
                        :data-vv-as="$t('labels.last_name')"
                    ></text-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="model.pickupInformation.contactPhone"
                        v-validate.immediate="
                            requirement
                                ? 'required|phonenumber|max:255'
                                : 'phonenumber|max:255'
                        "
                        v-tab-error:pickupDetails
                        name="pickupInformation.contactPhone"
                        label="labels.phone"
                        counter="255"
                        :error-messages="
                            errors.collect('pickupInformation.contactPhone')
                        "
                        :data-vv-as="$t('labels.phone')"
                        @blur="
                            model.pickupInformation.contactPhone = formatPhoneNumber(
                                model.pickupInformation.contactPhone
                            )
                        "
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="model.pickupInformation.contactEmail"
                        v-validate.immediate="
                            requirement ? 'required|email' : 'email'
                        "
                        v-tab-error:pickupDetails
                        name="pickupInformation.contactEmail"
                        label="labels.email"
                        counter="255"
                        :error-messages="
                            errors.collect('pickupInformation.contactEmail')
                        "
                        :data-vv-as="$t('labels.email')"
                    ></text-input>
                </template>
            </form-two-col-row>
            <form-one-col-row>
                <text-input
                    v-model="model.pickupInformation.contactCompany"
                    v-validate.immediate="
                        requirement
                            ? 'required|min:2|max:255|space'
                            : 'min:2|max:255|space'
                    "
                    v-tab-error:pickupDetails
                    name="pickupInformation.contactCompany"
                    label="labels.company"
                    counter="255"
                    :error-messages="
                        errors.collect('pickupInformation.contactCompany')
                    "
                    :data-vv-as="$t('labels.company')"
                ></text-input>
            </form-one-col-row>
            <form-one-col-row>
                <textarea-input
                    v-model="model.pickupInformation.pickupInstructions"
                    v-tab-error:pickupDetails
                    v-validate="'max:255|space'"
                    name="pickupInformation.pickupInstructions"
                    label="labels.instructions"
                    counter="255"
                    :error-messages="
                        errors.collect('pickupInformation.pickupInstructions')
                    "
                    :data-vv-as="$t('labels.instructions')"
                ></textarea-input>
            </form-one-col-row>
        </form-panel>
        <form-panel
            v-if="model.deliverySetting"
            :title="$t('labels.preparation_time')"
        >
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model.number="
                            model.deliverySetting.defaultPreparationTime
                        "
                        v-validate.immediate="
                            requirement
                                ? 'required|integer|min_value:0'
                                : 'integer|min_value:0'
                        "
                        v-tab-error:pickupDetails
                        name="pickupInformation.defaultPreparationTime"
                        label="labels.preparation_time_label"
                        :error-messages="
                            errors.collect(
                                'pickupInformation.defaultPreparationTime'
                            )
                        "
                        :data-vv-as="$t('labels.preparation_time_label')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
    </v-tab-item>
</template>

<script>
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import AddressInputs from '@/components/pages/addresses/AddressInputs'
import TextareaInput from '@/components/form/Inputs/TextareaInput'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        TextareaInput,
        AddressInputs,
    },

    mixins: [PhoneNumberMixin, FormTabMixin, FormInput],

    inject: {
        $validator: 'formValidator',
    },

    computed: {
        requirement() {
            return this.model?.deliverySetting?.orditManagedDelivery
        },
        addressErrors() {
            return this.errors.items.filter((error) =>
                error.field.startsWith('pickupAddressInputs')
            )
        },
    },

    methods: {
        clear() {
            this.model.pickupInformation.contactPhone = ''
            this.model.pickupInformation.contactCompany = ''
            this.model.pickupInformation.contactEmail = ''
            this.model.pickupInformation.contactFirstName = ''
            this.model.pickupInformation.contactLastName = ''
            this.model.pickupInformation.pickupInstructions = ''
            this.model.pickupInformation.fullAddress = ''
        },
    },
}
</script>
