<script>
import MealCategoryForm from '@/components/pages/meals/mealCategory/MealCategoryForm'

export default {
    components: {
        MealCategoryForm,
    },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            model: {
                name: null,
                restaurant: this.restaurant,
                isActive: true,
            },
            show: false,
        }
    },

    watch: {
        restaurant: {
            handler(restaurant) {
                this.$set(this.model, 'restaurant', restaurant)
            },
        },
    },

    methods: {
        toggleShow() {
            this.show = !this.show
            this.model.name = null
        },
    },
}
</script>

<template>
    <div class="add-meal-category mt-4 px-5">
        <v-btn @click="toggleShow">
            <v-icon left>add</v-icon>
            {{ $t('labels.add_meal_category') }}
        </v-btn>
        <meal-category-form
            v-if="show"
            v-model="model"
            @saved="toggleShow"
            @cancel="toggleShow"
        ></meal-category-form>
    </div>
</template>
