<script>
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import Accordion from '@/components/elements/Accordion'
import DeliveryRule from './DeliveryRule'
import TextInput from '@/components/form/Inputs/TextInput'

export default {
    components: { Accordion, DeliveryRule, TextInput },

    mixins: [FormInput, FormTabMixin],

    props: {
        value: {
            type: null,
            default: null,
        },

        enabled: {
            type: Boolean,
        },
    },

    computed: {
        computedRules() {
            let rules = []
            const model = [...this.model]
            if (this.model?.length) {
                model.sort((a, b) => {
                    let diff = this.ruleTimeTo$date(a.timeFrom).diff(
                        this.ruleTimeTo$date(b.timeFrom, 'm')
                    )
                    if (diff === 0) diff = a.distanceFrom - b.distanceFrom

                    return diff
                })
                for (let i = 0; i < model.length; i++) {
                    const idx = rules.findIndex((el) => {
                        return (
                            el.distanceFrom === model[i].distanceFrom &&
                            el.timeFrom ===
                                this.ruleTimeTo$date(model[i].timeFrom).format(
                                    'HH:mm'
                                )
                        )
                    })

                    if (
                        idx !== -1 &&
                        rules[idx].distanceTo === model[i].distanceTo
                    ) {
                        if (rules[idx].rules?.length) {
                            rules[idx].rules.push({ ...model[i], idx: i })
                        } else {
                            rules[idx].rules = [{ ...model[i], idx: i }]
                        }
                    } else {
                        const from = this.ruleTimeTo$date(
                            model[i].timeFrom
                        ).format('HH:mm')
                        const to = this.ruleTimeTo$date(model[i].timeTo).format(
                            'HH:mm'
                        )
                        rules.push({
                            distanceFrom: model[i].distanceFrom,
                            distanceTo: model[i].distanceTo,
                            timeFrom: from,
                            timeTo: to,
                            rules: [{ ...model[i], idx: i }],
                            idx: i,
                        })
                    }
                }
            }
            return rules
        },

        rulesByTime() {
            let rules = {}
            if (this.model?.length) {
                for (let i = 0; i < this.model.length; i++) {
                    const rule = this.model[i]
                    const key = `${this.ruleTimeTo$date(rule.timeFrom).format(
                        'HH:mm'
                    )}-${this.ruleTimeTo$date(rule.timeTo).format('HH:mm')}`
                    if (rules[key]) {
                        rules[key].push(rule)
                    } else {
                        rules[key] = [rule]
                    }
                }
            }
            return rules
        },

        rulesLength() {
            return this.model?.length ? this.model.length : 0
        },

        hasStart() {
            let hasStarts = true

            for (let i in this.rulesByTime) {
                let hasStart = false

                const rules = this.rulesByTime[i]
                for (let j = 0; j < rules.length; j++) {
                    if (rules[j].distanceFrom == 0) hasStart = true
                }

                hasStarts = hasStarts && hasStart
            }
            return hasStarts * 1
        },

        hasEnd() {
            let hasEnds = true

            for (let i in this.rulesByTime) {
                let hasEnd = false

                const rules = this.rulesByTime[i]
                for (let j = 0; j < rules.length; j++) {
                    if (!rules[j].distanceTo) hasEnd = true
                }

                hasEnds = hasEnds && hasEnd
            }
            return hasEnds * 1
        },

        isTimeContinuous() {
            let hasOverlap = false

            for (let i in this.rulesByTime) {
                const iRules = this.rulesByTime[i]
                const iFrom = this.ruleTimeTo$date(iRules[0].timeFrom)
                const iTo = this.ruleTimeTo$date(iRules[0].timeTo)
                let hasContinuation = iTo.format('HH:mm') === '23:59'

                for (let j in this.rulesByTime) {
                    if (i == j) continue
                    const jRules = this.rulesByTime[j]
                    const jFrom = this.ruleTimeTo$date(jRules[0].timeFrom)
                    const jTo = this.ruleTimeTo$date(jRules[0].timeTo)

                    hasContinuation =
                        hasContinuation ||
                        jFrom.isSame(iTo.add(1, 'minute')) ||
                        jTo.isSame(iFrom.subtract(1, 'minute'))

                    hasOverlap =
                        hasOverlap ||
                        iFrom.isSame(jFrom, 'minute') ||
                        iTo.isSame(jTo, 'minute') ||
                        (iFrom.isBefore(jFrom, 'minute') &&
                            iTo.isSameOrAfter(jFrom, 'minute')) ||
                        (iFrom.isAfter(jFrom, 'minute') &&
                            iTo.isSameOrBefore(jFrom, 'minute'))
                }

                if (!hasContinuation) hasOverlap = true
            }
            return !hasOverlap * 1
        },

        isDistanceContinuous() {
            let hasOverlap = false

            for (let i in this.rulesByTime) {
                const rules = this.rulesByTime[i]

                for (let j = 0; j < rules.length; j++) {
                    const jFrom = rules[j].distanceFrom
                    const jTo = rules[j].distanceTo || 10000000
                    let hasContinuation = !rules[j].distanceTo

                    for (let k = 0; k < rules.length; k++) {
                        if (j == k) continue

                        const kFrom = rules[k].distanceFrom
                        const kTo = rules[k].distanceTo

                        hasContinuation =
                            hasContinuation ||
                            kFrom == jTo + 1 ||
                            kTo == jFrom - 1

                        hasOverlap =
                            hasOverlap ||
                            ((jFrom == kFrom ||
                                jTo == kTo ||
                                (jFrom < kFrom && jTo >= kFrom) ||
                                (jFrom > kFrom && jTo <= kFrom)) &&
                                rules[j].deliveryUnitFrom ==
                                    rules[k].deliveryUnitFrom &&
                                rules[j].deliveryUnitTo ==
                                    rules[k].deliveryUnitTo)
                    }

                    if (!hasContinuation) hasOverlap = true
                }
            }

            return !hasOverlap * 1
        },

        hasTimeStarts() {
            return this.computedRules?.length
                ? this.computedRules.findIndex(
                      (el) =>
                          this.ruleTimeTo$date(el.timeFrom).format('HH:mm') ===
                          '00:00'
                  ) !== -1
                    ? 1
                    : 0
                : null
        },

        hasTimeEnds() {
            return this.computedRules?.length
                ? this.computedRules.findIndex(
                      (el) =>
                          this.ruleTimeTo$date(el.timeTo).format('HH:mm') ===
                          '23:59'
                  ) !== -1
                    ? 1
                    : 0
                : null
        },
    },

    watch: {
        hasStart() {
            this.resetErrors('deliveryRule.start')
        },

        hasEnd() {
            this.resetErrors('deliveryRule.end')
        },

        rulesLength() {
            this.resetErrors('deliveryRule.length')
        },

        isDistanceContinuous() {
            this.resetErrors('deliveryRule.dist_continuous')
        },

        hasTimeStarts() {
            this.resetErrors('deliveryRule.time_start')
        },

        hasTimeEnds() {
            this.resetErrors('deliveryRule.time_end')
        },
    },

    methods: {
        setRule(obj) {
            if (Array.isArray(obj.idx))
                for (let i = 0; i < obj.idx.length; i++)
                    this.$set(this.model[obj.idx[i]], obj.propKey, obj.value)
            else this.$set(this.model[obj.idx], obj.propKey, obj.value)
        },

        removeRule(idx) {
            this.model.splice(idx, 1)

            this.resetErrors()
        },

        getAccordionName(rule) {
            return `${rule.timeFrom} - ${rule.timeTo} |
                ${rule.distanceFrom} - ${
                rule.distanceTo ? rule.distanceTo : '∞'
            }`
        },

        resetErrors(field) {
            this.$nextTick(() => {
                this.$validator.validate(field)
            })
        },

        ruleTimeTo$date(ruletime) {
            let date = this.$date(ruletime)
            if (!date.isValid()) date = this.$date(`1970-01-01T${ruletime}:00`)

            return date
        },
    },
}
</script>
<template>
    <div class="delivery-rules">
        <accordion v-if="computedRules && computedRules.length" :eager="true">
            <delivery-rule
                v-for="(rule, index) in computedRules"
                ref="deliveryRule"
                :key="index"
                :value="rule"
                :name="getAccordionName(rule)"
                @set-rule="setRule"
                @remove="removeRule(index)"
                @added="$emit('added', $event)"
                @tabError="$emit('tabError', $event)"
            ></delivery-rule>
        </accordion>
        <div class="my-5">
            <div
                v-if="enabled && !rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_delivery_rules') }}
            </div>
            <div
                v-if="enabled && !hasStart && rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_distance_start') }}
            </div>
            <div
                v-if="enabled && !hasEnd && rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_distance_end') }}
            </div>
            <div
                v-if="enabled && !isDistanceContinuous && rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_rule_distance_continuous') }}
            </div>
            <div
                v-if="enabled && !isTimeContinuous && rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_rule_time_continuous') }}
            </div>
            <div
                v-if="enabled && !hasTimeStarts && rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_rule_time_start') }}
            </div>
            <div
                v-if="enabled && !hasTimeEnds && rulesLength"
                class="error--text v-messages mb-3"
            >
                {{ $t('labels.required_rule_time_end') }}
            </div>
        </div>
        <div class="hide-helper-inputs">
            <text-input
                ref="start"
                v-validate.immediate="'integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="hasStart"
                label="labels.required_distance_start"
                name="deliveryRule.start"
                :error-messages="errors.collect('deliveryRule.start')"
                :data-vv-as="$t('labels.required_distance_start')"
            ></text-input>
            <text-input
                ref="end"
                v-validate.immediate="'integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="hasEnd"
                label="labels.required_distance_end"
                name="deliveryRule.end"
                :error-messages="errors.collect('deliveryRule.end')"
                :data-vv-as="$t('labels.required_distance_end')"
            ></text-input>
            <text-input
                ref="length"
                v-validate.immediate="
                    enabled ? 'required|integer|min_value:1' : ''
                "
                v-tab-error:deliveryRules
                :value="rulesLength"
                label="labels.required_delivery_rules"
                name="deliveryRule.length"
                :error-messages="errors.collect('deliveryRule.length')"
                :data-vv-as="$t('labels.required_delivery_rules')"
            ></text-input>
            <text-input
                ref="dist_continuous"
                v-validate.immediate="'integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="isDistanceContinuous"
                label="labels.required_rule_distance_continuous"
                name="deliveryRule.dist_continuous"
                :error-messages="errors.collect('deliveryRule.dist_continuous')"
                :data-vv-as="$t('labels.required_rule_distance_continuous')"
            ></text-input>
            <text-input
                ref="time_continuous"
                v-validate.immediate="'integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="isTimeContinuous"
                label="labels.required_rule_time_continuous"
                name="deliveryRule.time_continuous"
                :error-messages="errors.collect('deliveryRule.time_continuous')"
                :data-vv-as="$t('labels.required_rule_time_continuous')"
            ></text-input>
            <text-input
                ref="time_start"
                v-validate.immediate="'integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="hasTimeStarts"
                label="labels.required_rule_time_start"
                name="deliveryRule.time_start"
                :error-messages="errors.collect('deliveryRule.time_start')"
                :data-vv-as="$t('labels.required_rule_time_start')"
            ></text-input>
            <text-input
                ref="time_end"
                v-validate.immediate="'integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="hasTimeEnds"
                label="labels.required_rule_time_end"
                name="deliveryRule.time_end"
                :error-messages="errors.collect('deliveryRule.time_end')"
                :data-vv-as="$t('labels.required_rule_time_end')"
            ></text-input>
        </div>
    </div>
</template>
