<script>
import MealOptionGroupForm from '@/components/pages/meals/mealOptions/MealOptionGroupForm'

export default {
    components: {
        MealOptionGroupForm,
    },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            model: {
                name: null,
                restaurant: this.restaurant,
                isActive: true,
                maxEachQuantity: 0,
                maxQuantity: 0,
                minQuantity: 0,
                code: '',
            },
            show: false,
        }
    },

    watch: {
        restaurant: {
            handler(restaurant) {
                this.$set(this.model, 'restaurant', restaurant)
            },
        },
    },

    methods: {
        toggleShow() {
            this.show = !this.show
            this.model = {
                name: null,
                restaurant: this.restaurant,
                isActive: true,
                maxEachQuantity: 0,
                maxQuantity: 0,
                minQuantity: 0,
                code: '',
            }

            if (!this.show) this.$emit('tab-error', {})
        },
    },
}
</script>

<template>
    <div class="add-meal-category mt-4 px-5">
        <v-btn @click="toggleShow">
            <v-icon left>add</v-icon>
            {{ $t('labels.add_meal_option_group') }}
        </v-btn>
        <meal-option-group-form
            v-if="show"
            v-model="model"
            @cancel="toggleShow"
            @saved="toggleShow"
            @tabError="$emit('tab-error', $event)"
        ></meal-option-group-form>
    </div>
</template>
