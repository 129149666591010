<template>
    <v-tab-item
        key="info_contact_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <contacts
            v-model="model.billingInfo.orderContacts"
            v-tab-error:contact
            name="orderContacts"
            :title="$t('labels.contact_for_delivery')"
            :error-messages="errors.all('orderContacts')"
        ></contacts>

        <contacts
            v-model="model.billingInfo.billingContacts"
            v-tab-error:contact
            name="billingContacts"
            :title="$t('labels.contact_for_billing')"
            :error-messages="errors.all('billingContacts')"
        ></contacts>
    </v-tab-item>
</template>

<script>
import FormInput from '@/components/mixins/FormInput'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import Contacts from '@/components/elements/Contacts'

export default {
    components: {
        Contacts,
    },
    inject: {
        $validator: 'formValidator',
    },
    mixins: [FormTabMixin, FormInput],
}
</script>
