<template lang="pug">
v-card.notification-switch(elevation="0" :class="{'pb-6': !notificationBlocked && !notificationsOn}")
    v-card-text
        v-btn.mb-3(:color="notificationsOn ? 'warning' : 'primary'" :loading="loading" :disabled="notificationBlocked" @click="setPermission")
            template(v-if="notificationsOn") {{$t('labels.turn_off_notifications')}}
            template(v-else) {{$t('labels.turn_on_notifications')}}

        v-fade-transition(mode="out-in")
            p.mb-0.g-73--text.absolute(v-if="!notificationBlocked && !notificationsOn") {{ $t('texts.not_receive_order_until_notification_on') }}

        .p-0.text-body-1.error--text.d-grid.grid-rows-0fr.transition-grid-row(:class="{'grid-rows-1fr': notificationBlocked}") 
            .overflow-hidden {{ $t('texts.notification_blocked_in_browser') }}
                v-img.w-50.mx-auto.mt-4(
                    v-for="(image, index) in helperImage" 
                    :src="image" 
                    :key="`helperImage-${index}`"
                    contain)
</template>

<script>
import NotificationsMixin from '@/components/mixins/NotificationsMixin'
export default {
    mixins: [NotificationsMixin],
}
</script>
