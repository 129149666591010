<script>
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/mixins/AuthMixin'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import SelectApiClientType from '@/api/SelectApiClientType'
import mealType from '@/store/type/mealType'
import authType from '@/store/type/authType'
import restaurantType from '@/store/type/restaurantType'

export default {
    components: { EntitySelectInput },
    mixins: [AuthMixin],
    data() {
        return {
            selectApiClientType: SelectApiClientType,
        }
    },
    computed: {
        ...mapGetters({
            restaurants: authType.getters.MANAGED_RESTAURANTS,
            restaurant: restaurantType.getters.ACTIVE_RESTAURANT,
        }),
        showSelector() {
            return this.restaurants?.length > 1
        },
        activeRestaurant: {
            get() {
                return this.restaurant ? this.restaurant['@id'] : undefined
            },
            set(restaurant) {
                if (restaurant && restaurant !== this.restaurant['@id']) {
                    this.$store.dispatch(mealType.actions.CLEAR_ALL)
                    this.$store
                        .dispatch(
                            restaurantType.actions.SET_ACTIVE_RESTAURANT_ID,
                            restaurant
                        )
                        .then((id) => {
                            this.$router.replace({
                                params: {
                                    id: id,
                                },
                            })
                        })
                }
            },
        },
    },
}
</script>

<template lang="pug">
.restaurant-selector(v-if="showSelector")
    entity-select-input(
        v-model="activeRestaurant"
        :clearable="false"
        label="labels.managed_restaurants"
        :entity="selectApiClientType.RESTAURANT"
        autocomplete)

</template>

<style scoped lang="scss"></style>
