<template>
    <div v-if="restaurantId" class="regenerate-token">
        <v-btn @click="confirmModal = true">
            {{ $t('labels.regenerate_token') }}
        </v-btn>
        <confirm-modal
            :value="confirmModal"
            :text="$t('texts.are_you_sure_you_want_to_regenerate_the_token')"
            :processing="processing"
            @onApprove="onApprove"
            @onCancel="onCancel"
        ></confirm-modal>
    </div>
</template>

<style scoped>
.regenerate-token {
    display: inline-block;
}
</style>

<script>
import ConfirmModal from '@/components/confirm/ConfirmModal'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'

export default {
    components: {
        ConfirmModal,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        restaurantId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            confirmModal: false,
            processing: false,
        }
    },
    methods: {
        onApprove() {
            this.processing = true
            RestaurantsApiClient.generateAccessToken(this.restaurantId)
                .then((response) => {
                    this.$emit('input', response['access-token'])
                })
                .finally(() => {
                    this.close()
                })
        },
        onCancel() {
            this.close()
        },
        close() {
            this.processing = false
            this.confirmModal = false
        },
    },
}
</script>
