<script>
import RestaurantSoftware from '@/components/pages/restaurants/RestaurantSoftware'
import AddOrderingSoftware from '@/components/pages/restaurants/AddOrderingSoftware'
import Accordion from '@/components/elements/Accordion'
import FormPanel from '@/components/form/FormPanel'
import ApproveEmailInput from '@/components/form/Inputs/ApproveEmailInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'

import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

import RestaurantSoftwareType from '@/enum/RestaurantSoftwareType'

export default {
    components: {
        RestaurantSoftware,
        AddOrderingSoftware,
        Accordion,
        FormPanel,
        ApproveEmailInput,
        CheckboxInput,
    },

    mixins: [FormTabMixin, FormInput],

    data() {
        return {
            approveEmails: [],
        }
    },

    computed: {
        softwareSettings() {
            return this.model?.softwareSettings?.length
                ? this.model.softwareSettings.filter((el) => {
                      return el.software !== RestaurantSoftwareType.ordit
                  })
                : []
        },
    },

    methods: {
        setSoftwareSetting(val) {
            const idx = this.model.softwareSettings.findIndex((el) => {
                return val['@id'] == el['@id']
            })
            this.$set(this.model.softwareSettings, idx, val)
        },

        removeSoftware(val) {
            const idx = this.model.softwareSettings.findIndex((el) => {
                return val['@id'] == el['@id']
            })

            this.$delete(this.model.softwareSettings, idx)
        },

        addSoftware(val) {
            this.$set(this.model, 'softwareSettings', [
                ...this.model.softwareSettings,
                val,
            ])
        },

        setError(val) {
            this.$set(this, 'tabErrorsContainer', val)
            this.$emit('tabError', val)
        },
    },
}
</script>

<template>
    <!-- ORDER TRANSFER TAB -->
    <v-tab-item
        key="order_transfer_tab"
        :disabled="!model['@id']"
        :transition="false"
        :reverse-transition="false"
    >
        <template v-if="model['@id']">
            <!-- APPROVE EMAILS -->
            <form-panel :title="$t('labels.approve_emails')">
                <approve-email-input
                    v-model="approveEmails"
                    v-tab-error:orderTransfer
                    v-validate.immediate="'email'"
                    name="approveEmail"
                    :error-messages="errors.collect('approveEmail')"
                    :approve-email.sync="model.approveEmail"
                    :additional-approve-emails.sync="
                        model.additionalApproveEmails
                    "
                    label="labels.approve_emails"
                    :data-vv-as="$t('labels.approve_emails')"
                ></approve-email-input>

                <!-- SENDONLYCANTEENCONFIRMEMAIL -->
                <checkbox-input
                    v-model="model.sendOnlyCanteenConfirmEmail"
                    v-tab-error:orderTransfer
                    label="labels.send_only_canteen_confirm_email"
                    name="sendOnlyCanteenConfirmEmail"
                    :error-messages="
                        errors.collect('sendOnlyCanteenConfirmEmail')
                    "
                ></checkbox-input>
            </form-panel>

            <form-panel :title="$t('labels.ordit_software')">
                <restaurant-software
                    v-model="model.orditSoftwareSetting"
                    :is-default="true"
                    @tabError="setError"
                ></restaurant-software>
            </form-panel>

            <form-panel :title="$t('labels.softwares')">
                <accordion v-if="softwareSettings && softwareSettings.length">
                    <restaurant-software
                        v-for="software in softwareSettings"
                        :key="software.id"
                        :name="software.software"
                        :value="software"
                        @input="setSoftwareSetting"
                        @remove-software="removeSoftware"
                        @tabError="setError"
                    ></restaurant-software>
                </accordion>
            </form-panel>

            <add-ordering-software @added="addSoftware"></add-ordering-software>
        </template>
    </v-tab-item>
</template>
