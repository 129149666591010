<template>
    <v-tab-item
        key="personal_pickup_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel :title="$t('labels.personal_pickup')">
            <form-one-col-row>
                <checkbox-input
                    v-model="
                        model.personalPickupInformation
                            .personalCollectionEnabled
                    "
                    name="model.personalPickupInformation.personalCollectionEnabled"
                    label="labels.personal_pickup_enabled"
                ></checkbox-input>
            </form-one-col-row>

            <address-inputs
                v-model="model.personalPickupInformation"
                v-tab-error:personalPickup
                name="personalPickupAddressInputs"
                zip-code-prop="postCode"
                town-prop="city"
                clearable
                :required="requirement"
                :error-messages="addressErrors"
                @clear="clear"
            ></address-inputs>

            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="model.personalPickupInformation.contactPhone"
                        v-validate.immediate="
                            requirement
                                ? 'required|phonenumber|max:255'
                                : 'phonenumber|max:255'
                        "
                        v-tab-error:personalPickup
                        name="personalPickupInformation.contactPhone"
                        label="labels.phone"
                        :error-messages="
                            errors.collect(
                                'personalPickupInformation.contactPhone'
                            )
                        "
                        :data-vv-as="$t('labels.phone')"
                        @blur="
                            model.personalPickupInformation.contactPhone = formatPhoneNumber(
                                model.personalPickupInformation.contactPhone
                            )
                        "
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <currency-input
                        v-model="
                            model.personalPickupInformation.minimumOrderValue
                        "
                        v-validate.immediate="requirement ? 'required' : ''"
                        v-tab-error:personalPickup
                        name="personalPickupInformation.minimumOrderValue"
                        label="labels.minimum_order"
                        :error-messages="
                            errors.collect(
                                'personalPickupInformation.minimumOrderValue'
                            )
                        "
                        :data-vv-as="$t('labels.minimum_order')"
                    ></currency-input>
                </template>
            </form-two-col-row>
            <form-one-col-row>
                <image-editor
                    v-model="model.personalPickupInformation"
                    :primary-size="{ width: 1920, height: 1080 }"
                    primary-key="photoOfThePlace"
                    primary-label="labels.photo_of_the_place"
                    :quality="0.85"
                ></image-editor>
            </form-one-col-row>
        </form-panel>
    </v-tab-item>
</template>

<script>
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'

import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'
import AddressInputs from '@/components/pages/addresses/AddressInputs'
import ImageEditor from '@/components/pages/meals/ImageEditor'

import { isPPIfilled } from '@/services/Restaurants/PersonalPickupInformation'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        CheckboxInput,
        CurrencyInput,
        AddressInputs,
        ImageEditor,
    },

    mixins: [FormTabMixin, FormInput, PhoneNumberMixin],

    inject: {
        $validator: 'formValidator',
    },

    computed: {
        requirement() {
            return isPPIfilled(this.model?.personalPickupInformation)
        },
        addressErrors() {
            return this.errors.items.filter((error) =>
                error.field.startsWith('personalPickupAddressInputs')
            )
        },
    },

    methods: {
        clear() {
            this.model.personalPickupInformation.contactPhone = null
            this.model.personalPickupInformation.minimumOrderValue = 0
            this.model.personalPickupInformation.photoOfThePlace = null
        },
    },
}
</script>
