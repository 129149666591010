<script>
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import TextInput from '@/components/form/Inputs/TextInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import SelectApiClientType from '@/api/SelectApiClientType'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'

export default {
    components: { TextInput, EntitySelectInput, CheckboxInput },

    mixins: [FormInput, FormTabMixin],

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        index: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            selectApiClientType: SelectApiClientType,
        }
    },

    computed: {
        methodsLength() {
            return this.model?.length ? this.model?.length : 0
        },
    },

    watch: {
        methodsLength() {
            this.$nextTick(() => {
                this.$validator.validateAll()
            })
        },
    },
}
</script>
<template>
    <div class="delivery-methods">
        <div v-for="(method, i) in model" :key="i" class="row">
            <div class="col-md-5">
                <entity-select-input
                    v-validate.immediate="'required'"
                    v-tab-error:deliveryRules
                    :value="method.method"
                    label="labels.delivery_method"
                    :data-vv-as="$t('labels.delivery_method')"
                    :entity="selectApiClientType.DELIVERY_METHOD"
                    :name="'deliveryRule.deliveryMethod' + i"
                    translate-items
                    :error-messages="
                        errors.collect('deliveryRule.deliveryMethod' + i)
                    "
                    @input="model[i].method = $event"
                ></entity-select-input>
            </div>
            <div class="col-md-3">
                <checkbox-input
                    v-model="method.backup"
                    v-tab-error:deliveryRules
                    :disabled="true"
                    name="deliveryRule.backup"
                    label="labels.backup"
                ></checkbox-input>
            </div>
            <div class="col-md-4">
                <v-btn
                    class="ma-2"
                    outlined
                    color="error"
                    @click="$emit('remove', i)"
                >
                    <v-icon left>remove</v-icon>
                    {{ $t('actions.remove') }}
                </v-btn>
            </div>
        </div>
        <div class="hide-helper-inputs">
            <text-input
                v-validate.immediate="'required|integer|min_value:1'"
                v-tab-error:deliveryRules
                :value="methodsLength"
                label="labels.required_delivery_methods"
                :name="'deliveryRule.methodsLength' + index"
                :error-messages="
                    errors.collect('deliveryRule.methodsLength' + index)
                "
                :data-vv-as="$t('labels.required_delivery_methods')"
            ></text-input>
        </div>

        <div v-if="!methodsLength" class="error--text v-messages my-3">
            {{ $t('labels.required_delivery_methods') }}
        </div>
    </div>
</template>
