<script>
import FormPanel from '@/components/form/FormPanel'
import TextInput from '@/components/form/Inputs/TextInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

export default {
    components: {
        FormPanel,
        TextInput,
        DatePickerInput,
    },

    data() {
        return {
            ruleModel: {
                '@type': 'DeliveryRule',
                distanceFrom: null,
                distanceTo: null,
                timeFrom: '00:00',
                timeTo: '23:59',
                deliveryUnitFrom: null,
                deliveryUnitTo: null,
                vehicleType: null,
                numberOfVehicle: 1,
                thermalBagNeeded: false,
                thermalBoxNeeded: false,
                deliveryMethods: [],
            },
        }
    },

    computed: {
        isDisabled() {
            return (
                this.ruleModel.distanceFrom === '' ||
                this.ruleModel.distanceFrom === null ||
                this.hasDeliveryRuleError
            )
        },
        hasDeliveryRuleError() {
            return (
                this.errors?.items.findIndex((el) => {
                    return (
                        el.field.includes('deliveryRule.') &&
                        !el.field.includes('deliveryRule.length')
                    )
                }) !== -1
            )
        },
    },

    methods: {
        add() {
            this.$emit('added', { ...this.ruleModel })
            this.ruleModel.distanceFrom = null
            this.ruleModel.distanceTo = null
            this.ruleModel.timeFrom = '00:00'
            this.ruleModel.timeTo = '23:59'
        },
    },
}
</script>

<template>
    <form-panel :title="$t('labels.add_delivery_rule')">
        <div class="row">
            <div class="col-md-4 pb-0">
                <text-input
                    v-model.number="ruleModel.distanceFrom"
                    v-validate="'required|integer|min_value:0'"
                    name="deliveryRule.distanceFrom"
                    label="labels.distance_from"
                ></text-input>
            </div>
            <div class="col-md-4 pb-0">
                <text-input
                    v-model.number="ruleModel.distanceTo"
                    v-validate="'integer|min_value:0'"
                    name="deliveryRule.distanceTo"
                    label="labels.distance_to"
                ></text-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 py-0">
                <date-picker-input
                    v-model="ruleModel.timeFrom"
                    v-validate="'date_format:HH:mm'"
                    name="deliveryRule.timeFrom"
                    label="labels.delivery_rule_time_from"
                    only-time
                ></date-picker-input>
            </div>
            <div class="col-md-4 py-0">
                <date-picker-input
                    v-model="ruleModel.timeTo"
                    v-validate="'date_format:HH:mm'"
                    name="deliveryRule.timeTo"
                    label="labels.delivery_rule_time_to"
                    only-time
                ></date-picker-input>
            </div>
            <div class="col-md-4 py-0">
                <v-btn
                    class="ma-2"
                    :disabled="isDisabled"
                    outlined
                    color="success"
                    @click="add"
                >
                    <v-icon left>add</v-icon> {{ $t('actions.add') }}
                </v-btn>
            </div>
        </div>
    </form-panel>
</template>
