<script>
import ModalDialog from '@/components/dialog/ModalDialog'
import FileInput from '@/components/form/Inputs/FileInput'
import Notification from '@/services/Notification/Notification'
import RestApiType from '@/api/RestApiType'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import mealType from '@/store/type/mealType'
import { mapActions } from 'vuex'

export default {
    components: {
        ModalDialog,
        FileInput,
    },

    props: {
        restaurant: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            dialog: false,
            formData: null,
            disabled: true,
            importErrors: null,
            showProgress: false,
            progress: 0,
            indeterminate: false,
        }
    },

    methods: {
        ...mapActions({
            updateList: mealType.actions.REFRESH_LIST,
        }),

        importMeal() {
            this.showProgress = true
            this.progress = 0

            RestaurantsApiClient.import(
                this.restaurant,
                this.formData,
                this.onProgress.bind(this)
            )
                .then(() => {
                    this.formData = null
                    this.dialog = false
                    Notification.importSuccessful('restaurants.import_title')
                    this.updateList(RestApiType.MEAL_CATEGORIES)
                })
                .catch((e) => {
                    if (e.response?.data?.errors?.categories) {
                        this.$refs.categoriesInput.reset()
                    }
                    if (e.response?.data?.errors?.meals) {
                        this.$refs.mealsInput.reset()
                    }
                    if (e.response?.data?.errors?.['option-groups']) {
                        this.$refs.optionsInput.reset()
                    }
                    this.importErrors = e.response?.data?.errors
                })
                .finally(() => {
                    this.progress = 0
                    this.showProgress = false
                    this.indeterminate = false
                })
        },

        setFormData(file, name) {
            if (!this.formData) this.formData = new FormData()

            if (!file) {
                this.formData.delete(name)
            } else {
                this.formData.append(name, file)
                this.importErrors = null
            }

            this.disabled =
                !this.formData ||
                !this.formData.has('categories') ||
                !this.formData.has('meals') ||
                !this.formData.has('option-groups')
        },

        onProgress(progressEvent) {
            this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            )
            if (this.progress > 99) {
                this.indeterminate = true
            }
        },
    },
}
</script>
<template>
    <div class="import-meals d-inline-block">
        <v-btn color="success" @click="dialog = true">
            {{ $t('actions.import') }}
        </v-btn>
        <modal-dialog
            v-model="dialog"
            :title="$t('labels.import_meals')"
            cancel-button
            :max-width="480"
            :ok-disabled="disabled || showProgress"
            @ok="importMeal"
            @cancel="setFormData"
        >
            <div v-if="dialog">
                <file-input
                    ref="categoriesInput"
                    name="categories"
                    accepted-files="text/csv"
                    label="labels.meal_categories"
                    @input="setFormData($event, 'categories')"
                ></file-input>
                <file-input
                    ref="mealsInput"
                    name="meals"
                    accepted-files="text/csv"
                    label="labels.meals"
                    @input="setFormData($event, 'meals')"
                ></file-input>
                <file-input
                    ref="optionsInput"
                    name="options"
                    accepted-files="text/csv"
                    label="labels.meal_options"
                    @input="setFormData($event, 'option-groups')"
                ></file-input>
                <ul v-if="importErrors" class="import-errors">
                    <li v-for="(csvErrors, csv) in importErrors" :key="csv">
                        <h4 class="mt-0 mb-2">
                            {{ csv + ':' }}
                        </h4>
                        <ul>
                            <li
                                v-for="(error, rowIndex) in csvErrors"
                                :key="rowIndex"
                            >
                                {{ rowIndex + ':' }}
                                <ul>
                                    <li
                                        v-for="(errorType, columnKey) in error"
                                        :key="columnKey"
                                    >
                                        {{ columnKey }}
                                        <ul>
                                            <li
                                                v-for="message in errorType"
                                                :key="message.message"
                                            >
                                                {{ message.message }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <template v-slot:footer>
                <v-progress-linear
                    :value="progress"
                    :active="showProgress"
                    :indeterminate="indeterminate"
                    class="mr-2"
                ></v-progress-linear>
            </template>
        </modal-dialog>
    </div>
</template>

<style lang="scss">
.import-errors {
    max-height: 200px;
    overflow-y: scroll;
    background-color: var(--v-g-f0-base);
    padding: 12px;
    border-radius: 3px;
    font-size: 12px;
    margin: 0;

    li {
        font-size: 12px;
    }
}
</style>
