<script>
import { mapGetters } from 'vuex'
import mealType from '@/store/type/mealType'

import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'

import MealOptionGroupMenu from '@/components/pages/meals/mealOptions/MealOptionGroupMenu'
import AddMealOptionGroup from '@/components/pages/meals/mealOptions/AddMealOptionGroup'
import MealOptionGroup from '@/components/pages/meals/mealOptions/MealOptionGroup'
import MealOptionList from '@/components/pages/meals/mealOptions/MealOptionList'
import AddMealOption from '@/components/pages/meals/mealOptions/AddMealOption'

export default {
    components: {
        MealOptionGroupMenu,
        AddMealOptionGroup,
        MealOptionGroup,
        MealOptionList,
        AddMealOption,
    },

    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            api: RestApiType.MEAL_OPTION_GROUP,
        }
    },

    computed: {
        ...mapGetters({
            mealOptionGroup: mealType.getters.MEAL_OPTION_GROUP,
            mealOption: mealType.getters.MEAL_OPTION,
        }),
    },

    watch: {
        $route: {
            handler() {
                if (
                    !this.$route.query.meal_option_group &&
                    this.mealOptionGroup.restaurant
                )
                    this.$store.dispatch(mealType.actions.MEAL_OPTION_GROUP, {})

                if (
                    this.$route.query.meal_option_group &&
                    !this.mealOptionGroup.restaurant
                ) {
                    RestApiCollection.get(this.api)
                        .get(this.$route.query.meal_option_group)
                        .then((res) => {
                            this.$store.dispatch(
                                mealType.actions.MEAL_OPTION_GROUP,
                                res
                            )
                        })
                }
            },
            immediate: true,
        },
    },
    methods: {
        onNewOption() {
            this.$refs.optionlist.updateItems()
        },
    },
}
</script>

<template>
    <v-tab-item
        key="meal_option_groups_tab"
        :disabled="!value['@id']"
        :transition="false"
        :reverse-transition="false"
    >
        <v-row v-if="value['@id']" class="fill-height">
            <v-col lg="4" md="6">
                <meal-option-group-menu
                    :restaurant="value['@id']"
                ></meal-option-group-menu>
                <add-meal-option-group
                    :restaurant="value['@id']"
                    @tab-error="$emit('tab-error', $event)"
                ></add-meal-option-group>
            </v-col>
            <v-col lg="8" md="6">
                <template v-if="mealOptionGroup && mealOptionGroup.id">
                    <meal-option-group
                        class="mb-5"
                        :restaurant="value['@id']"
                        :meal-option-group="mealOptionGroup"
                        @tab-error="$emit('tab-error', $event)"
                    ></meal-option-group>
                    <meal-option-list
                        ref="optionlist"
                        :restaurant="value['@id']"
                        :meal-option-group="mealOptionGroup"
                        @tab-error="$emit('tab-error', $event)"
                    ></meal-option-list>
                    <add-meal-option
                        :restaurant="value['@id']"
                        :meal-option-group="mealOptionGroup['@id']"
                        @tab-error="$emit('tab-error', $event)"
                        @saved="onNewOption"
                    ></add-meal-option>
                </template>
            </v-col>
        </v-row>
    </v-tab-item>
</template>
