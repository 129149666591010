var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{key:"personal_pickup_tab",attrs:{"transition":false,"reverse-transition":false,"eager":""}},[_c('form-panel',{attrs:{"title":_vm.$t('labels.personal_pickup')}},[_c('form-one-col-row',[_c('checkbox-input',{attrs:{"name":"model.personalPickupInformation.personalCollectionEnabled","label":"labels.personal_pickup_enabled"},model:{value:(
                    _vm.model.personalPickupInformation
                        .personalCollectionEnabled
                ),callback:function ($$v) {_vm.$set(_vm.model.personalPickupInformation
                        , "personalCollectionEnabled", $$v)},expression:"\n                    model.personalPickupInformation\n                        .personalCollectionEnabled\n                "}})],1),_c('address-inputs',{directives:[{name:"tab-error",rawName:"v-tab-error:personalPickup",arg:"personalPickup"}],attrs:{"name":"personalPickupAddressInputs","zip-code-prop":"postCode","town-prop":"city","clearable":"","required":_vm.requirement,"error-messages":_vm.addressErrors},on:{"clear":_vm.clear},model:{value:(_vm.model.personalPickupInformation),callback:function ($$v) {_vm.$set(_vm.model, "personalPickupInformation", $$v)},expression:"model.personalPickupInformation"}}),_c('form-two-col-row',{scopedSlots:_vm._u([{key:"col1",fn:function(){return [_c('text-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:(
                        _vm.requirement
                            ? 'required|phonenumber|max:255'
                            : 'phonenumber|max:255'
                    ),expression:"\n                        requirement\n                            ? 'required|phonenumber|max:255'\n                            : 'phonenumber|max:255'\n                    ",modifiers:{"immediate":true}},{name:"tab-error",rawName:"v-tab-error:personalPickup",arg:"personalPickup"}],attrs:{"name":"personalPickupInformation.contactPhone","label":"labels.phone","error-messages":_vm.errors.collect(
                            'personalPickupInformation.contactPhone'
                        ),"data-vv-as":_vm.$t('labels.phone')},on:{"blur":function($event){_vm.model.personalPickupInformation.contactPhone = _vm.formatPhoneNumber(
                            _vm.model.personalPickupInformation.contactPhone
                        )}},model:{value:(_vm.model.personalPickupInformation.contactPhone),callback:function ($$v) {_vm.$set(_vm.model.personalPickupInformation, "contactPhone", $$v)},expression:"model.personalPickupInformation.contactPhone"}})]},proxy:true},{key:"col2",fn:function(){return [_c('currency-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:(_vm.requirement ? 'required' : ''),expression:"requirement ? 'required' : ''",modifiers:{"immediate":true}},{name:"tab-error",rawName:"v-tab-error:personalPickup",arg:"personalPickup"}],attrs:{"name":"personalPickupInformation.minimumOrderValue","label":"labels.minimum_order","error-messages":_vm.errors.collect(
                            'personalPickupInformation.minimumOrderValue'
                        ),"data-vv-as":_vm.$t('labels.minimum_order')},model:{value:(
                        _vm.model.personalPickupInformation.minimumOrderValue
                    ),callback:function ($$v) {_vm.$set(_vm.model.personalPickupInformation, "minimumOrderValue", $$v)},expression:"\n                        model.personalPickupInformation.minimumOrderValue\n                    "}})]},proxy:true}])}),_c('form-one-col-row',[_c('image-editor',{attrs:{"primary-size":{ width: 1920, height: 1080 },"primary-key":"photoOfThePlace","primary-label":"labels.photo_of_the_place","quality":0.85},model:{value:(_vm.model.personalPickupInformation),callback:function ($$v) {_vm.$set(_vm.model, "personalPickupInformation", $$v)},expression:"model.personalPickupInformation"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }