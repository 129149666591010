<script>
import EditMealOptionGroup from '@/components/pages/meals/mealOptions/EditMealOptionGroup'

export default {
    components: { EditMealOptionGroup },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
        mealOptionGroup: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
}
</script>
<template>
    <div class="meal-category">
        <div :id="'meal-option-group-' + mealOptionGroup.id">
            <h2 class="my-4">
                {{ mealOptionGroup.name }}
            </h2>
            <edit-meal-option-group
                @tab-error="$emit('tab-error', $event)"
            ></edit-meal-option-group>
        </div>
    </div>
</template>
