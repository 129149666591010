<script>
import FormTwoColRow from '@/components/form/FormTwoColRow'
import ReadOnlyText from '@/components/form/Inputs/ReadOnlyText'
import RegenerateToken from '@/components/pages/restaurants/RegenerateToken'
import DeleteToken from '@/components/pages/restaurants/DeleteToken'
import FormPanel from '@/components/form/FormPanel'

import FormInput from '@/components/mixins/FormInput'

export default {
    components: {
        ReadOnlyText,
        RegenerateToken,
        DeleteToken,
        FormTwoColRow,
        FormPanel,
    },

    mixins: [FormInput],
}
</script>

<template>
    <!-- TOKEN TAB -->
    <v-tab-item key="token_tab" :transition="false" :reverse-transition="false">
        <form-panel :title="$t('labels.token')">
            <form-two-col-row>
                <template v-slot:col1>
                    <read-only-text
                        label=""
                        :value="model.accessToken"
                    ></read-only-text
                    ><br />
                    <regenerate-token
                        v-model="model.accessToken"
                        :restaurant-id="model.id"
                        style="margin-right: 10px"
                    ></regenerate-token>
                    <delete-token
                        v-model="model.accessToken"
                        :restaurant-id="model.id"
                    ></delete-token>
                </template>
            </form-two-col-row>
        </form-panel>
    </v-tab-item>
</template>
