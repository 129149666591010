<script>
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import AuthMixin from '@/components/mixins/AuthMixin'
import StrongConfirmModal from '@/components/confirm/StrongConfirmModal'

export default {
    components: { StrongConfirmModal },
    mixins: [AuthMixin],

    props: {
        restaurant: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            processing: false,
            dialog: false,
        }
    },

    methods: {
        clearMenu() {
            this.processing = true
            RestaurantsApiClient.clearMenu(this.restaurant)
                .then(() => {
                    this.$emit('clear-menu')
                    this.dialog = false
                })
                .finally(() => {
                    this.processing = false
                })
        },
    },
}
</script>

<template lang="pug">
.d-inline-flex.flex-wrap(v-if="isSuperAdmin")
    v-btn(
        color="error"
        class="white--text"
        @click.stop="dialog = true"
    ) {{ $t('actions.clear_menu') }}
    strong-confirm-modal(
        :value="dialog"
        :title="$t('labels.clear_menu')"
        :text="$t('texts.are_you_sure')"
        :processing="processing"
        approve-color="error"
        @onApprove="clearMenu"
        @onCancel="dialog = false")
</template>
