<script>
import FormInput from '@/components/mixins/FormInput'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'

import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import FormTabMixin from '@/components/mixins/FormTabMixin'

import { mapGetters } from 'vuex'
import mealType from '@/store/type/mealType'
import authType from '@/store/type/authType'

export default {
    components: {
        TextInput,
        CheckboxInput,
        ConfirmModal,
    },

    mixins: [FormInput, FormTabMixin],

    data() {
        return {
            api: RestApiType.MEAL_CATEGORIES,
            dialog: false,
        }
    },

    computed: {
        ...mapGetters({
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
    },

    methods: {
        save() {
            if (!this.model.name) return

            if (this.model.id) {
                RestApiCollection.get(this.api)
                    .update(this.model, this.model.id)
                    .then((res) => {
                        this.success(res)
                    })
            } else {
                RestApiCollection.get(this.api)
                    .create(this.model)
                    .then((res) => {
                        this.success(res)
                    })
            }
        },

        deleteCategory() {
            RestApiCollection.get(this.api)
                .delete(this.model.id)
                .then(() => {
                    this.$store.dispatch(
                        mealType.actions.REFRESH_LIST,
                        this.api
                    )
                    this.$store.dispatch(mealType.mutations.MEAL_CATEGORY, {})
                })
        },

        success(res) {
            this.$store.dispatch(mealType.actions.REFRESH_LIST, this.api)
            this.$store.dispatch(mealType.mutations.MEAL_CATEGORY, res)
            this.$emit('saved')
        },
    },
}
</script>

<template>
    <div class="category-form">
        <form @submit.prevent="save">
            <text-input
                v-model="model.name"
                name="name"
                label="labels.name"
                :error-messages="errors.collect('name')"
                :data-vv-as="$t('labels.name')"
            ></text-input>
            <text-input
                v-if="!isRestaurantAdmin"
                v-model="model.code"
                v-tab-error:menu
                v-validate="'space'"
                name="code"
                label="labels.code"
                :error-messages="errors.collect('code')"
                :data-vv-as="$t('labels.code')"
            ></text-input>
            <checkbox-input
                v-model="model.isActive"
                label="labels.active"
                name="isActive"
            ></checkbox-input>
            <div
                class="d-flex justify-end mt-4"
                :class="{
                    'justify-end': !model.id || isRestaurantAdmin,
                    'justify-space-between': model.id && !isRestaurantAdmin,
                }"
            >
                <div v-if="model.id && !isRestaurantAdmin">
                    <v-btn color="error" class="mr-3" @click="dialog = true">
                        {{ $t('actions.delete') }}
                    </v-btn>
                </div>
                <div>
                    <v-btn
                        variant="secondary"
                        class="mr-3"
                        @click="$emit('cancel')"
                    >
                        {{ $t('actions.cancel') }}
                    </v-btn>
                    <v-btn
                        color="success"
                        :disabled="!model.name"
                        type="submit"
                    >
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
            </div>
            <confirm-modal
                v-model="dialog"
                :title="$t('texts.are_you_sure')"
                @onApprove="deleteCategory"
            ></confirm-modal>
        </form>
    </div>
</template>
