<script>
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import PolygonInput from '@/components/form/Inputs/google/PolygonInput'
import CircleInput from '@/components/form/Inputs/google/CircleInput'
import DeliveryAreaVariant from './DeliveryAreaVariant'

import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import isEmpty from 'lodash/isEmpty'

const variantModel = {
    minimumOrder: 0,
    deliveryPrice: 0,
    deliveryMinTime: 0,
    deliveryMaxTime: 0,
    isPreOrder: false,
    preOrderBorderTime: null,
    preOrderDayBefore: null,
}

export default {
    components: {
        FormOneColRow,
        FormTwoColRow,
        TextInput,
        CheckboxInput,
        PolygonInput,
        CircleInput,
        DeliveryAreaVariant,
    },

    mixins: [FormInput, FormTabMixin],

    props: {
        modelObject: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    computed: {
        showRadius() {
            return this.modelObject.deliveryAreaType == 'circle'
        },
    },

    watch: {
        model(newmodel) {
            if (newmodel && newmodel?.variants) {
                if (newmodel?.variants?.length == 0) {
                    this.addVariant()
                }
            }
        },

        'model.deliveryDelay'(val) {
            if (val == '') this.$set(this.model, 'deliveryDelay', 0)
        },

        'model.deliveryPriceLimit'(val) {
            if (val == '') this.$set(this.model, 'deliveryPriceLimit', '0')
        },
    },

    methods: {
        setVariant(variant, index) {
            if (!isEmpty(variant) && this.model?.variants) {
                this.$set(this.model.variants, index, variant)
            }
        },

        removeVariant(index) {
            this.$delete(this.model.variants, index)
        },

        addVariant() {
            const variant = { ...variantModel }
            let highestVariant = null

            this.model.variants.forEach((variant) => {
                if (
                    !highestVariant ||
                    variant.minimumOrder > highestVariant.minimumOrder
                ) {
                    highestVariant = variant
                }
            })

            if (highestVariant) {
                variant.minimumOrder = highestVariant.minimumOrder
                variant.deliveryPrice = highestVariant.deliveryPrice
                variant.deliveryMinTime = highestVariant.deliveryMinTime
                variant.deliveryMaxTime = highestVariant.deliveryMaxTime
            }

            this.model.variants.push(variant)
        },
    },
}
</script>
<template>
    <div v-if="model" class="delivery-area">
        <form-two-col-row>
            <template v-slot:col1>
                <text-input
                    v-model="model.name"
                    v-validate.immediate="'required|min:2|max:255|space'"
                    v-tab-error:deliveryZones
                    name="name"
                    label="labels.name"
                    :error-messages="errors.collect('name')"
                    :data-vv-as="$t('labels.name')"
                ></text-input>

                <form-two-col-row>
                    <template v-slot:col1>
                        <text-input
                            v-model="model.deliveryPriceLimit"
                            v-tab-error:deliveryZones
                            name="deliveryPriceLimit"
                            label="labels.delivery_price_limit"
                            :error-messages="
                                errors.collect('deliveryPriceLimit')
                            "
                            :data-vv-as="$t('labels.delivery_price_limit')"
                        ></text-input>
                    </template>
                </form-two-col-row>

                <form-two-col-row>
                    <template v-slot:col1>
                        <text-input
                            v-model="model.deliveryDelay"
                            v-tab-error:deliveryZones
                            v-validate.immediate="'required|integer'"
                            type="number"
                            name="deliveryDelay"
                            label="labels.delivery_delay"
                            :error-messages="errors.collect('deliveryDelay')"
                            :data-vv-as="$t('labels.delivery_delay')"
                        ></text-input>
                    </template>
                    <template v-slot:col2>
                        <checkbox-input
                            v-model="model.isActive"
                            v-tab-error:deliveryZones
                            v-validate="''"
                            label="labels.active"
                            name="isActive"
                            :error-messages="errors.collect('isActive')"
                        ></checkbox-input>
                    </template>
                </form-two-col-row>
            </template>
            <template v-slot:col2>
                <polygon-input
                    v-if="!showRadius"
                    v-model="model"
                    style="height: 300px; margin-bottom: 66px"
                    :model-object="modelObject"
                    @tabError="tabErrorsContainer = $event"
                ></polygon-input>
                <circle-input
                    v-else
                    v-model="model"
                    style="height: 300px"
                    :model-object="modelObject"
                ></circle-input>
                <text-input
                    v-if="showRadius"
                    v-model.number="model.deliveryRadius"
                    v-validate="'required|integer|space'"
                    v-tab-error:deliveryZones
                    type="number"
                    name="deliveryRadius"
                    label="labels.delivery_radius"
                    :error-messages="errors.collect('deliveryRadius')"
                    :data-vv-as="$t('labels.delivery_radius')"
                ></text-input>
            </template>
        </form-two-col-row>

        <form-one-col-row class="pb-4">
            <div v-for="(variant, index) in model.variants" :key="index">
                <delivery-area-variant
                    :value="variant"
                    :show-delete="index > 0"
                    @input="setVariant($event, index)"
                    @remove-variant="removeVariant(index)"
                ></delivery-area-variant>
            </div>
        </form-one-col-row>

        <div>
            <v-btn class="ma-2" outlined color="success" @click="addVariant">
                <v-icon left>add</v-icon> {{ $t('actions.add') }}
            </v-btn>
        </div>

        <div class="d-flex justify-end">
            <v-btn
                class="ma-2"
                outlined
                color="error"
                @click="$emit('remove-area', model)"
            >
                <v-icon left>remove</v-icon> {{ $t('actions.remove') }}
            </v-btn>
        </div>
    </div>
</template>
