<template>
    <div class="meal-options">
        <data-iterator
            v-if="mealOptionGroup && mealOptionGroup.id"
            ref="iterator"
            :api="api"
            class="dandd-iterator"
            :filters="{}"
            :sort-by="null"
            :sort-desc="null"
            :fixed-filters="{ parentGroup: mealOptionGroup['@id'] }"
            :mapper="mapper"
            :show-overlay="showOverlay"
            disable-query-params
            disable-pagination
        >
            <template slot-scope="{ items }">
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <h2 class="mb-4">
                                {{ $t('labels.meal_options') }}
                            </h2>
                        </v-list-item-content>
                    </v-list-item>
                    <draggable
                        :value="items"
                        handle=".handle-option"
                        @change="onDragEnd($event, items)"
                    >
                        <v-list-group
                            v-for="(item, index) in items"
                            :key="item['@id']"
                            :ref="`itemList-${item.id}`"
                            class="c-pointer show-item-on-hover"
                            :value="
                                mealOption && mealOption.id == item.id
                                    ? true
                                    : undefined
                            "
                        >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title class="handle-option">
                                        <v-icon> drag_indicator </v-icon>
                                        {{ index + 1 + '. ' + item.name }}
                                        <v-list-item-content
                                            v-if="!item.isActive"
                                            class="py-0"
                                        >
                                            <v-list-item-title class="ml-10">
                                                <span
                                                    class="text-body-2 red--text font-italic"
                                                >
                                                    {{
                                                        inactiveLabel(
                                                            item.inactivatedUntil
                                                        )
                                                    }}
                                                </span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <modal-activate-for-today
                                    class="item-on-hover activate-button option-btn"
                                    :api="api"
                                    :model="item"
                                    @saved="
                                        $refs.iterator.updateItems(true, true)
                                    "
                                ></modal-activate-for-today>
                            </template>
                            <ul class="sub-items">
                                <v-list-item>
                                    <meal-option-form
                                        class="w-100"
                                        :value="item"
                                        @saved="
                                            $refs.iterator.updateItems(true)
                                        "
                                        @deleteOption="confirmDelete"
                                        @cancel="cancel(item)"
                                        @tabError="$emit('tab-error', $event)"
                                    ></meal-option-form>
                                </v-list-item>
                            </ul>
                        </v-list-group>
                    </draggable>
                </v-list>
            </template>
        </data-iterator>
        <confirm-modal
            v-model="dialog"
            :title="$t('texts.are_you_sure')"
            @onApprove="deleteOption"
        ></confirm-modal>
    </div>
</template>

<script>
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import MealOptionForm from '@/components/pages/meals/mealOptions/MealOptionForm'

import DataIterator from '@/components/table/DataIterator'
import Draggable from 'vuedraggable'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import ModalActivateForToday from '@/components/pages/meals/ModalActivateForToday'

import mealType from '@/store/type/mealType'
import { mapGetters } from 'vuex'

export default {
    components: {
        MealOptionForm,
        DataIterator,
        Draggable,
        ConfirmModal,
        ModalActivateForToday,
    },

    props: {
        mealOptionGroup: {
            type: Object,
            default: () => {
                return {}
            },
        },
        restaurant: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            mealOptions: [],
            api: RestApiType.MEAL_OPTION,
            mapper: MapperType.MEAL_OPTION,
            showOverlay: false,
            dialog: false,
            deletedOption: null,
            open: [],
        }
    },

    computed: {
        ...mapGetters({
            mealOption: mealType.getters.MEAL_OPTION,
        }),
    },

    watch: {
        mealOptionGroup: {
            handler() {
                this.getMealOptions()
            },
            immediate: true,
        },
        dialog() {
            if (!this.dialog) this.deletedOption = null
        },
    },

    mounted() {
        this.$store.subscribe((mutation) => {
            if (mutation.type == mealType.mutations.MEAL_OPTION) {
                if (this.$refs && this.$refs.iterator)
                    this.$refs.iterator.updateItems()
            }
        })
    },

    methods: {
        getMealOptions() {
            RestApiCollection.get(this.api)
                .list({ parentGroup: this.mealOptionGroup['@id'] })
                .then((res) => {
                    this.mealOptions = this.mapper
                        ? MapperCollection.get(this.mapper).map(
                              res['hydra:member']
                          )
                        : res['hydra:member']
                })
        },

        onDragEnd(event, items) {
            if (
                !event.moved ||
                !event.moved.element ||
                !event.moved.element.id
            ) {
                this.$refs.iterator.updateItems()
            }

            const idx = items[event.moved.newIndex].position

            this.showOverlay = true

            RestApiCollection.get(this.api)
                .moveToPosition(event.moved.element.id, idx)
                .finally(() => {
                    this.showOverlay = false
                    this.$refs.iterator.updateItems(true)
                })
        },

        confirmDelete(val) {
            this.deletedOption = val
            this.dialog = true
        },

        deleteOption() {
            RestApiCollection.get(this.api)
                .delete(this.deletedOption)
                .then(() => {
                    this.$refs.iterator.updateItems(true)
                    this.deletedOption = null
                })
        },
        cancel(item) {
            let group = this.$refs[`itemList-${item.id}`]
            if (group) group[0].toggle()
        },
        inactiveLabel: function (inactiveForToday) {
            return inactiveForToday
                ? `${this.$t('labels.inactive')} (${this.$t(
                      'labels.inactivate_for_today'
                  )})`
                : `${this.$t('labels.inactive')} (${this.$t(
                      'labels.inactivate_for_indefinitely'
                  )})`
        },
        updateItems() {
            this.$refs.iterator.updateItems(true)
        },
    },
}
</script>
