<script>
import MealImagesUpload from '@/components/pages/meals/MealImagesUpload'

export default {
    components: {
        MealImagesUpload,
    },

    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
}
</script>
<template lang="pug">
    <!-- MEAL IMAGES TAB -->
    v-tab-item(key='meal_images_tab' :disabled="!value['@id']" :transition='false' :reverse-transition='false')
        meal-images-upload(:restaurant="value" @tab-error="$emit('tab-error', $event)")

</template>
