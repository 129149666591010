<template>
    <overlay :show="showOverlay">
        <draggable
            class="row mt-5"
            :value="meals"
            handle=".handle-meal"
            @change="onDragEnd"
        >
            <v-col v-for="meal in meals" :key="meal.id" lg="6" md="12">
                <meal-card
                    class="handle-meal c-pointer"
                    :model="meal"
                    @saved="getMeals"
                ></meal-card>
            </v-col>
        </draggable>
    </overlay>
</template>

<script>
import MealsApiClient from '@/api/RestApi/MealsApiClient'
import MealListMapper from '@/services/mapper/MealListMapper'
import Draggable from 'vuedraggable'
import Overlay from '@/components/overlay/Overlay'
import MealCard from '@/components/pages/meals/MealCard'

export default {
    components: { MealCard, Draggable, Overlay },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
        category: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            meals: [],
            showOverlay: false,
        }
    },
    watch: {
        category: {
            handler() {
                this.getMeals()
            },
            immediate: true,
        },
    },

    methods: {
        getMeals() {
            MealsApiClient.list({ category: this.category['@id'] }).then(
                (res) => {
                    this.meals = MealListMapper.map(res['hydra:member'])
                    this.$emit('items-updated')
                }
            )
        },
        onDragEnd(event) {
            if (
                !event.moved ||
                !event.moved.element ||
                !event.moved.element.id
            ) {
                this.$refs.iterator.updateItems()
            }

            const idx = this.meals[event.moved.newIndex].position

            this.showOverlay = true

            MealsApiClient.moveToPosition(event.moved.element.id, idx).finally(
                () => {
                    this.showOverlay = false
                    this.getMeals()
                }
            )
        },
    },
}
</script>
