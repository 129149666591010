<script>
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'

export default {
    props: {
        restaurant: {
            type: Number,
            default: 0,
        },
    },

    methods: {
        exportMeal(type) {
            if (type == 'categories') {
                RestaurantsApiClient.exportCategories(this.restaurant).then(
                    (res) => {
                        this.openCsv(res, type)
                    }
                )
            } else if (type == 'meals') {
                RestaurantsApiClient.exportMeals(this.restaurant).then(
                    (res) => {
                        this.openCsv(res, type)
                    }
                )
            } else {
                RestaurantsApiClient.exportOptions(this.restaurant).then(
                    (res) => {
                        this.openCsv(res, type)
                    }
                )
            }
        },

        openCsv(text, type) {
            const blob = new Blob([text], {
                type: 'text/csv;charset=utf-8;',
            })
            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, type + '.csv')
            } else {
                let link = document.createElement('a')
                if (link.download !== undefined) {
                    let url = URL.createObjectURL(blob)
                    link.setAttribute('href', url)
                    link.setAttribute('download', type + '.csv')
                    link.style.visibility = 'hidden'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }
            }
        },
    },
}
</script>
<template>
    <div class="export-meals d-inline-flex flex-wrap">
        <v-btn class="mr-3" variant="primary" @click="exportMeal('categories')">
            {{ $t('actions.export_category') }}
        </v-btn>
        <v-btn class="mr-3" variant="primary" @click="exportMeal('meals')">
            {{ $t('actions.export_meal') }}
        </v-btn>
        <v-btn class="mr-3" variant="primary" @click="exportMeal('options')">
            {{ $t('actions.export_options') }}
        </v-btn>
    </div>
</template>
