<template>
    <div class="meal-option-form">
        <v-form v-model="isValid">
            <text-input
                v-model="model.name"
                v-tab-error:mealOptionGroups
                v-validate.immediate="'required|space'"
                name="name"
                label="labels.name"
                :error-messages="errors.collect('name')"
                :data-vv-as="$t('labels.name')"
            ></text-input>
            <text-input
                v-if="isRestaurantAdmin"
                v-model="model.restaurantPrice"
                v-tab-error:mealOptionGroups
                v-validate="'required|decimal:2'"
                name="price"
                label="labels.price"
                :error-messages="errors.collect('price')"
                :data-vv-as="$t('labels.price')"
            ></text-input>
            <text-input
                v-else
                v-model="model.price"
                v-tab-error:mealOptionGroups
                v-validate="'required|decimal:2'"
                name="price"
                label="labels.price"
                :error-messages="errors.collect('price')"
                :data-vv-as="$t('labels.price')"
            ></text-input>
            <form-two-col-row v-if="!isRestaurantAdmin">
                <template v-slot:col1>
                    <text-input
                        v-model="model.discountPrice"
                        v-tab-error:mealOptionGroups
                        name="discountPrice"
                        label="labels.discountPrice"
                        :error-messages="errors.collect('discountPrice')"
                        :data-vv-as="$t('labels.discountPrice')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="model.supplierPrice"
                        v-tab-error:mealOptionGroups
                        name="supplierPrice"
                        label="labels.supplier_price"
                        :error-messages="errors.collect('supplierPrice')"
                        :data-vv-as="$t('labels.supplier_price')"
                    ></text-input>
                </template>
            </form-two-col-row>
            <textarea-input
                v-model="model.description"
                v-validate="'space'"
                v-tab-error:mealOptionGroups
                name="description"
                label="labels.description"
                :error-messages="errors.collect('description')"
                :data-vv-as="$t('labels.description')"
            ></textarea-input>
            <text-input
                v-model="model.code"
                v-tab-error:mealOptionGroups
                v-validate="'space'"
                name="code"
                label="labels.code"
                :error-messages="errors.collect('code')"
                :data-vv-as="$t('labels.code')"
            ></text-input>
            <text-input
                v-if="!isRestaurantAdmin"
                v-model.number="model.deliveryUnit"
                v-tab-error:mealOptionGroups
                v-validate.immediate="'required|numeric|min_value:0'"
                name="deliveryUnit"
                label="labels.delivery_unit"
                :error-messages="errors.collect('deliveryUnit')"
                :data-vv-as="$t('labels.delivery_unit')"
            ></text-input>
            <checkbox-input
                v-model="model.isActive"
                label="labels.active"
                name="isActive"
            ></checkbox-input>
            <form-two-col-row>
                <template v-slot:col1>
                    <entity-select-input
                        v-model="model.valueAddedTax"
                        v-tab-error:mealOptionGroups
                        v-validate.immediate="'required'"
                        label="labels.vat"
                        name="valueAddedTax"
                        :entity="selectApiClientType.VAT"
                        :data-vv-as="$t('labels.vat')"
                        :error-messages="errors.collect('valueAddedTax')"
                    ></entity-select-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="model.kcal"
                        v-tab-error:menu
                        v-validate="'space|integer'"
                        name="kcal"
                        label="labels.kcal"
                        :error-messages="errors.collect('kcal')"
                        :data-vv-as="$t('labels.kcal')"
                    ></text-input>
                </template>
            </form-two-col-row>
            <div class="d-flex justify-end px-4 pt-4">
                <div>
                    <v-btn
                        v-if="model.id && !isRestaurantAdmin"
                        class="mr-4"
                        color="warning"
                        @click="deleteOption"
                    >
                        {{ $t('actions.delete') }}
                    </v-btn>
                    <v-btn
                        v-else
                        variant="secondary"
                        class="mr-3"
                        @click="cancel"
                    >
                        {{ $t('actions.cancel') }}
                    </v-btn>
                    <v-btn
                        color="success"
                        :disabled="!model.name || !isValid"
                        @click="save"
                    >
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import TextareaInput from '@/components/form/Inputs/TextareaInput'

import FormTabMixin from '@/components/mixins/FormTabMixin'

import SelectApiClientType from '@/api/SelectApiClientType'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import FormInput from '@/components/mixins/FormInput'
import FormTwoColRow from '@/components/form/FormTwoColRow'

import mealType from '@/store/type/mealType'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'

export default {
    components: {
        TextInput,
        CheckboxInput,
        TextareaInput,
        EntitySelectInput,
        FormTwoColRow,
    },

    mixins: [FormTabMixin, FormInput],

    data() {
        return {
            isValid: false,
            model: {},
            selectApiClientType: SelectApiClientType,
            api: RestApiType.MEAL_OPTION,
        }
    },

    computed: {
        ...mapGetters({
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
    },

    methods: {
        save() {
            const entity = MapperCollection.get(
                MapperType.MEAL_OPTION_FORM
            ).toEntity(this.model)

            if (this.model.id) {
                RestApiCollection.get(this.api)
                    .update(entity, this.model.id)
                    .then(() => {
                        this.$emit('saved')
                    })
            } else {
                RestApiCollection.get(this.api)
                    .create(entity)
                    .then((res) => {
                        this.$emit('saved')
                        this.$store.dispatch(mealType.actions.MEAL_OPTION, res)
                    })
            }
        },

        deleteOption() {
            this.$emit('deleteOption', this.model.id)
        },

        cancel() {
            this.$emit('cancel')
        },
    },
}
</script>
