<template>
    <div>
        <v-combobox
            :value="value"
            :label="labelText"
            :error-messages="errorMessages"
            :name="name"
            append-icon=""
            multiple
            chips
            clearable
            deletable-chips
            @input="$emit('input', $event)"
        ></v-combobox>
        <p class="font-italic">{{ $t('labels.combobox_hint') }}</p>
    </div>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

export default {
    mixins: [ErrorMsgInputMixin],
    props: {
        value: {
            type: Array,
            default: () => {
                return []
            },
        },
        approveEmail: {
            type: String,
            default: null,
        },
        additionalApproveEmails: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    computed: {
        emails() {
            const emails = []

            if (this.approveEmail) {
                emails.push(this.approveEmail)
            }

            return emails.concat(this.additionalApproveEmails)
        },
    },
    watch: {
        value(value) {
            if (value.length > 0) {
                const emails = CloneDeep(value)

                this.$emit('update:approveEmail', emails.shift())
                this.$emit('update:additionalApproveEmails', emails)

                return
            }

            this.$emit('update:approveEmail', null)
            this.$emit('update:additionalApproveEmails', [])
        },
        emails: {
            immediate: true,
            handler(emails) {
                if (!isEqual(emails, this.value)) {
                    this.$emit('input', emails)
                }
            },
        },
    },
}
</script>
