<template>
    <data-iterator
        v-if="restaurant"
        ref="iterator"
        class="dandd-iterator"
        :api="api"
        :sort-by="null"
        :sort-desc="null"
        :filters="{ restaurant: restaurant }"
        :mapper="listMapper"
        :show-overlay="showOverlay"
        disable-query-params
        disable-pagination
        @items-updated="fetchLastupdate"
    >
        <template slot-scope="{ items }">
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <h2 class="mb-1">
                            {{ $t('labels.menu') }}
                        </h2>
                        <div class="g-73--text text-subtitle-2">
                            {{ $t('labels.last_modified') }}:
                            {{ lastUpdateFormatted }}
                        </div>
                    </v-list-item-content>
                </v-list-item>
                <draggable
                    :value="items"
                    handle=".handle"
                    @change="onDragEnd($event, items)"
                >
                    <v-list-item
                        v-for="(item, index) in items"
                        :key="item['@id']"
                        class="c-pointer show-item-on-hover"
                        :class="{ active: item.id == mealCategory.id }"
                        @click="setCategory(item)"
                    >
                        <v-list-item-title class="handle">
                            <v-icon> drag_indicator </v-icon>
                            {{ index + 1 + '. ' + item.name }}
                            <v-list-item-content
                                v-if="!item.isActive"
                                class="py-0"
                            >
                                <v-list-item-title class="ml-10">
                                    <span
                                        class="text-body-2 red--text font-italic"
                                    >
                                        {{
                                            inactiveLabel(item.inactivatedUntil)
                                        }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item-title>
                        <modal-activate-for-today
                            class="item-on-hover activate-button"
                            :api="api"
                            :model="item"
                            @saved="$refs.iterator.updateItems(true, true)"
                        ></modal-activate-for-today>
                    </v-list-item>
                </draggable>
            </v-list>
        </template>
    </data-iterator>
</template>

<script>
import { mapGetters } from 'vuex'
import { getId } from '@/services/IdFromIri'
import { DATE_TIME_FORMAT } from '@/const/DateTimeFormats'
import throttle from 'lodash/throttle'
import mealType from '@/store/type/mealType'
import RestApiType from '@/api/RestApiType'
import MealCategoriesApiClient from '@/api/RestApi/MealCategoriesApiClient'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import DataIterator from '@/components/table/DataIterator'
import MapperType from '@/services/mapper/MapperType'
import Draggable from 'vuedraggable'
import ModalActivateForToday from '@/components/pages/meals/ModalActivateForToday'

export default {
    components: {
        DataIterator,
        Draggable,
        ModalActivateForToday,
    },

    props: {
        restaurant: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            api: RestApiType.MEAL_CATEGORIES,
            listMapper: MapperType.MEAL_CATEGORY_LIST,
            showOverlay: false,
            lastUpdate: null,
            fetchFn: null,
        }
    },

    computed: {
        ...mapGetters({
            mealCategory: mealType.getters.MEAL_CATEGORY,
        }),
        lastUpdateFormatted() {
            return this.lastUpdate
                ? this.$date(this.lastUpdate).format(DATE_TIME_FORMAT)
                : ''
        },
    },

    mounted() {
        this.fetchFn = throttle(
            () => {
                RestaurantsApiClient.menuLastupdate(
                    getId(this.restaurant)
                ).then((res) => {
                    this.lastUpdate = res.lastUpdate
                })
            },
            1000,
            {
                leading: true,
                trailing: false,
            }
        )
    },

    methods: {
        onDragEnd(event, items) {
            if (
                !event.moved ||
                !event.moved.element ||
                !event.moved.element.id
            ) {
                this.$refs.iterator.updateItems()
            }

            this.showOverlay = true

            const idx = items[event.moved.newIndex].position

            MealCategoriesApiClient.moveToPosition(
                event.moved.element.id,
                idx
            ).finally(() => {
                this.showOverlay = false
                this.$refs.iterator.updateItems(true)
            })
        },

        inactiveLabel: function (inactiveForToday) {
            return inactiveForToday
                ? `${this.$t('labels.inactive')} (${this.$t(
                      'labels.inactivate_for_today'
                  )})`
                : `${this.$t('labels.inactive')} (${this.$t(
                      'labels.inactivate_for_indefinitely'
                  )})`
        },

        setCategory(item) {
            if (this.$route.query.meal)
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        meal: undefined,
                    },
                })

            this.$store
                .dispatch(mealType.actions.MEAL_CATEGORY, item)
                .then(() => {
                    this.$nextTick(() => {
                        let docViewTop = window.scrollY
                        let docViewBottom = docViewTop + window.innerHeight

                        let elem = document.getElementById(
                            'meal-category-' + item.id
                        )
                        let elemTop = elem.offsetTop
                        let elemBottom = elemTop + elem.offsetHeight

                        let inIt =
                            elemBottom <= docViewBottom && elemTop >= docViewTop
                        if (!inIt)
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            })

                        if (this.$route.query.meal_category != item.id)
                            this.$router.push({
                                query: {
                                    ...this.$route.query,
                                    meal_category: item.id,
                                },
                            })
                    })
                })
        },

        refreshList() {
            this.$refs.iterator.updateItems(true)
        },

        fetchLastupdate() {
            this.fetchFn && this.fetchFn()
        },
    },
}
</script>
