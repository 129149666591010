<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import FormInput from '@/components/mixins/FormInput'

export default {
    mixins: [FormInput],
    props: {
        zoom: {
            type: Number,
            default: 11,
        },
        options: {
            type: Object,
            default() {
                return {
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: true,
                    zoomControl: true,
                    scaleControl: false,
                    rotateControl: false,
                    disableDefaultUi: true,
                }
            },
        },
        modelObject: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    computed: {
        ...mapGetters({
            defaultCenter: configType.getters.GOOGLE_MAPS_DEFAULT_CENTER,
        }),

        point() {
            return {
                lat:
                    this.modelObject.latitude == null
                        ? this.defaultCenter.lat
                        : this.modelObject.latitude,
                lng:
                    this.modelObject.longitude == null
                        ? this.defaultCenter.lng
                        : this.modelObject.longitude,
            }
        },

        otherCircles() {
            if (this.modelObject.areas?.length) {
                return this.modelObject.areas.map((el) => {
                    return { id: el['@id'], deliveryRadius: el.deliveryRadius }
                })
            }
            return []
        },
    },
    watch: {
        point(point) {
            this.centerOnMap(point)
        },
    },

    mounted() {
        this.centerOnMap(this.point)
    },

    methods: {
        centerOnMap(point) {
            this.$refs.map.$mapPromise.then((map) => {
                map.panTo(point)
            })
        },

        radiusChanged(val) {
            this.$set(this.model, 'deliveryRadius', Math.floor(val))
        },
    },
}
</script>

<template>
    <div class="google-circle">
        <gmap-map
            ref="map"
            :center="defaultCenter"
            :zoom="zoom"
            :options="options"
            class="input-map-frame"
        >
            <gmap-circle
                ref="circle"
                :editable="true"
                :center="point"
                :radius="model.deliveryRadius"
                :options="{ fillColor: '#262626', strokeColor: '#262626' }"
                @radius_changed="radiusChanged"
            ></gmap-circle>
            <gmap-marker :position="point"></gmap-marker>
            <gmap-circle
                v-for="circle in otherCircles"
                :key="circle.id"
                :center="point"
                :radius="circle.deliveryRadius"
                :options="{ fillColor: '#2E7DFA', strokeColor: '#2E7DFA' }"
            ></gmap-circle>
        </gmap-map>
    </div>
</template>
