<template>
    <div>
        <div v-for="(limit, index) in limits" :key="index" class="pb-4">
            {{ $t('texts.software_limit_part_1') }}
            <currency-input
                v-validate="'min_value:0|required'"
                v-tab-error:orderTransfer
                class="currency-input-field"
                :value="limit.minOrder"
                :name="getName(index, 'minOrder')"
                :error-messages="errors.collect(getName(index, 'minOrder'))"
                :data-vv-as="$t('labels.min_order_value')"
                @input="updateLimit(index, 'minOrder', $event)"
            ></currency-input>
            {{ $t('labels.and') }}
            <currency-input
                v-validate="limit.maxOrder ? 'min_value:0' : ''"
                v-tab-error:orderTransfer
                class="currency-input-field"
                :value="limit.maxOrder"
                :name="getName(index, 'maxOrder')"
                :error-messages="errors.collect(getName(index, 'maxOrder'))"
                :data-vv-as="$t('labels.max_order_value')"
                @input="updateLimit(index, 'maxOrder', $event)"
            ></currency-input>
            {{ $t('texts.software_limit_part_2') }}
            <text-input
                v-validate="
                    limit.timeBeforeInMinute ? 'integer|min_value:0' : 'space'
                "
                class="input-field"
                type="number"
                :value="limit.timeBeforeInMinute"
                :name="getName(index, 'timeBeforeInMinute')"
                :error-messages="
                    errors.collect(getName(index, 'timeBeforeInMinute'))
                "
                :data-vv-as="$t('labels.transfer_time')"
                @input="updateLimit(index, 'timeBeforeInMinute', $event)"
            ></text-input>
            {{ $t('texts.software_limit_part_3') }}
            <v-btn icon color="warning" @click="deleteLimit(index)">
                <v-icon>delete</v-icon>
            </v-btn>
        </div>
        <v-btn class="ma-2" outlined color="success" @click="addLimit">
            <v-icon left>add</v-icon> {{ $t('actions.add') }}
        </v-btn>
    </div>
</template>

<style scoped>
.input-field {
    display: inline-block;
    width: 80px;
    margin-right: 10px;
    margin-left: 10px;
}
.currency-input-field {
    display: inline-block;
    width: 110px;
    margin-right: 10px;
    margin-left: 10px;
}
</style>

<script>
import TextInput from '@/components/form/Inputs/TextInput'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'
import CloneDeep from 'lodash/cloneDeep'
import FormTabMixin from '@/components/mixins/FormTabMixin'

export default {
    components: {
        TextInput,
        CurrencyInput,
    },
    mixins: [FormTabMixin],
    inject: {
        $validator: 'formValidator',
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },
    computed: {
        limits() {
            return this.value.softwareLimits ? this.value.softwareLimits : []
        },
    },
    methods: {
        updateLimit(index, field, value) {
            const settings = CloneDeep(this.value)

            settings.softwareLimits[index][field] = value

            this.$emit('input', settings)
        },
        deleteLimit(index) {
            const settings = CloneDeep(this.value)

            settings.softwareLimits.splice(index, 1)

            this.$emit('input', settings)
        },
        addLimit() {
            const settings = CloneDeep(this.value)

            settings.softwareLimits.push({})

            this.$emit('input', settings)
        },
        getName(index, field) {
            return this.name + '_' + index + '_' + field
        },
    },
}
</script>
