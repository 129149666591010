<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import FormInput from '@/components/mixins/FormInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import TextInput from '@/components/form/Inputs/TextInput'

const jsts = require('jsts')
const wktReader = new jsts.io.WKTReader()

export default {
    components: { CheckboxInput, TextInput },

    mixins: [FormInput, FormTabMixin],

    props: {
        zoom: {
            type: Number,
            default: 11,
        },
        options: {
            type: Object,
            default() {
                return {
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: true,
                    zoomControl: true,
                    drawingControl: true,
                    scaleControl: false,
                    rotateControl: false,
                    disableDefaultUi: true,
                }
            },
        },
        modelObject: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            drawingManager: null,
            polygon: null,
            isNotOverlapping: false,
        }
    },

    computed: {
        ...mapGetters({
            defaultCenter: configType.getters.GOOGLE_MAPS_DEFAULT_CENTER,
        }),

        point() {
            return {
                lat:
                    this.modelObject.latitude == null
                        ? this.defaultCenter.lat
                        : this.modelObject.latitude,
                lng:
                    this.modelObject.longitude == null
                        ? this.defaultCenter.lng
                        : this.modelObject.longitude,
            }
        },

        paths() {
            if (this.model?.area?.length) {
                return this.model.area.map((a) => {
                    return a.map((b) => {
                        return { lat: parseFloat(b[0]), lng: parseFloat(b[1]) }
                    })
                })
            }
            return []
        },

        otherPaths() {
            if (this.modelObject.areas?.length) {
                const areas = this.modelObject.areas.filter((el) => {
                    return el['@id'] != this.model['@id']
                })

                return areas
                    .map((a) => {
                        return a.area
                    })
                    .map((b) => {
                        return b?.length ? b[0] : []
                    })
                    .map((c) => {
                        return c?.length
                            ? c.map((d) => {
                                  return {
                                      lat: parseFloat(d[0]),
                                      lng: parseFloat(d[1]),
                                  }
                              })
                            : []
                    })
            }
            return []
        },

        coordinates: {
            get() {
                if (this.paths?.length) {
                    let paths = this.paths[0] || []
                    return paths
                        .map((el) => {
                            return `${el.lat}:${el.lng}`
                        })
                        .join('|')
                }
                return ''
            },
            set(val) {
                let paths = val.split('|')

                paths = paths.map((el) => {
                    return el.split(':')
                })

                for (let i = 0; i < paths.length; i++) {
                    if (
                        paths[i].length < 2 ||
                        isNaN(parseFloat(paths[i][0])) ||
                        isNaN(parseFloat(paths[i][1]))
                    ) {
                        return
                    }
                }

                this.$set(this.model, 'area', [paths])

                this.checkOverlap()
            },
        },
    },

    watch: {
        point(point) {
            this.centerOnMap(point)
        },
    },

    mounted() {
        this.centerOnMap(this.point)
        this.checkInitialOverlap()

        if (!this.paths.length) {
            this.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                },
            })

            this.drawingManager.addListener('polygoncomplete', (polygon) => {
                this.polygon = polygon
                this.drawingOff()
            })
        }
    },

    methods: {
        centerOnMap(point) {
            this.$refs.map.$mapPromise.then((map) => {
                map.panTo(point)

                google.maps.event.trigger(this.$refs.map, 'resize')

                if (this.drawingManager)
                    this.drawingManager.setMap(this.$refs.map.$mapObject)
            })
        },

        checkInitialOverlap() {
            this.$refs.polygon.$polygonPromise.then(() => {
                this.checkOverlap()
            })
        },

        updateEdited(mvcArray) {
            let paths = []
            for (let i = 0; i < mvcArray.getLength(); i++) {
                let path = []
                for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
                    let point = mvcArray.getAt(i).getAt(j)
                    path.push([point.lat(), point.lng()])
                }
                paths.push(path)
            }

            this.$set(this.model, 'area', paths)

            this.checkOverlap()
        },

        handleClickForDelete(event) {
            if (event.vertex) {
                this.$refs.polygon.$polygonObject
                    .getPaths()
                    .getAt(event.path)
                    .removeAt(event.vertex)
            }
        },

        drawingOff() {
            this.drawingManager.setDrawingMode(null)
            this.drawingManager.setOptions({
                drawingControl: false,
            })

            let path = this.polygon.getPath()

            let paths = []
            for (let i = 0; i < path.getLength(); i++) {
                let point = path.getAt(i)
                paths.push([point.lat(), point.lng()])
            }

            this.polygon.setMap(null)

            this.$set(this.model, 'area', [paths])

            this.$refs.polygon.$polygonObject.setMap(null)

            this.$nextTick(() => {
                this.$refs.polygon.$polygonObject.setMap(
                    this.$refs.map.$mapObject
                )
            })

            path.addListener('set_at', this.checkOverlap())
            path.addListener('insert_at', this.checkOverlap())
            path.addListener('remove_at', this.checkOverlap())
        },

        polygonLoaded(polygon) {
            this.polygon = polygon
            this.drawingOff()
        },

        checkOverlap() {
            if (!this.$refs.polygon.$polygonObject || !this.paths.length) return

            const geom = wktReader.read(this.getWktString(this.paths[0]))

            let overlap = false
            let poly = null

            for (let i in this.otherPaths) {
                poly = this.getWktString(this.otherPaths[i])
                poly = wktReader.read(poly)
                overlap = overlap || geom.intersects(poly)
            }

            if (overlap) {
                this.$refs.polygon.$polygonObject.setOptions({
                    fillColor: '#ff0000',
                    strokeColor: '#ff0000',
                })

                this.isNotOverlapping = false
            } else {
                this.$refs.polygon.$polygonObject.setOptions({
                    fillColor: '#000000',
                    strokeColor: '#000000',
                })

                this.isNotOverlapping = true
            }
        },

        getWktString(path) {
            const wtkPath = [...path, path[0]]
            let str = wtkPath
                .map((el) => {
                    return el.lat + ' ' + el.lng
                })
                .join(', ')

            return `POLYGON ((${str}))`
        },
    },
}
</script>

<template>
    <div class="google-polygon">
        <gmap-map
            ref="map"
            :center="defaultCenter"
            :zoom="zoom"
            :options="options"
            class="input-map-frame"
        >
            <gmap-polygon
                ref="polygon"
                :editable="true"
                :paths="paths"
                @paths_changed="updateEdited"
                @rightclick="handleClickForDelete"
            ></gmap-polygon>
            <gmap-polygon
                ref="otherPolygon"
                :paths="otherPaths"
                :options="{ fillColor: '#2E7DFA', strokeColor: '#2E7DFA' }"
            ></gmap-polygon>
        </gmap-map>
        <checkbox-input
            v-model="isNotOverlapping"
            v-tab-error:deliveryZones
            v-validate="'required:true'"
            class="hide-input"
            label="labels.polygon_not_overlapping"
            name="isNotOverlapping"
            :error-messages="errors.collect('isNotOverlapping')"
        ></checkbox-input>
        <text-input v-model="coordinates" class="mb-5"></text-input>
    </div>
</template>

<style lang="scss">
.google-polygon {
    width: 100%;
    background-color: var(--v-g-f3-base);
    min-height: 300px;
}
</style>
