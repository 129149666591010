<script>
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import MediaApiClient from '@/api/RestApi/MediaApiClient'

import RestApiType from '@/api/RestApiType'
import RestApiCollection from '@/api/RestApiCollection'
import ImageUploadInput from '@/components/form/Inputs/ImageUploadInput'
import MealImageSelector from '@/components/pages/meals/MealImageSelector'
import selectapiType from '@/store/type/selectapiType'
import SelectApiClientType from '@/api/SelectApiClientType'
import baseFormType from '@/store/type/baseFormType'
import MapperType from '@/services/mapper/MapperType'
import Notification from '@/services/Notification/Notification'

export default {
    components: {
        MealImageSelector,
        ImageUploadInput,
        FormPanel,
        FormOneColRow,
    },

    props: {
        restaurant: Object,
    },

    data() {
        return {
            loading: false,
            loaded: false,
            uploaded: false,
            files: null,
            images: null,
            meals: [],
            requestErrors: [],
            api: RestApiType.MEAL,
            selectApiClientType: SelectApiClientType,
        }
    },

    computed: {
        isActive() {
            return (parseInt(this.$route.query.tab) || 0) === 7
        },
        saveDisabled() {
            return !this.images.find((image) => image.meal)
        },
        selectedMeals() {
            return this.images
                .filter((image) => image.meal)
                .map((image) => image.meal)
                .flat(1)
        },
    },
    watch: {
        restaurant: {
            immediate: true,
            handler(val) {
                if (val && val['@id']) {
                    this.$store
                        .dispatch(
                            selectapiType.getActionName(
                                this.selectApiClientType.MEAL,
                                selectapiType.actions.LOAD
                            ),
                            { query: { restaurant: val['@id'] } }
                        )
                        .then((data) => {
                            this.meals = data
                        })
                }
            },
        },
    },

    methods: {
        filesSelected(files) {
            this.loaded = false
            this.images = files?.map((file, index) => {
                return {
                    tempID: index,
                    meal: null,
                    thumbnail: file,
                    cover: file,
                }
            })
        },
        clearFiles() {
            this.files = null
            this.images = null
        },
        getPromises(image, type, meal) {
            return MediaApiClient.upload(image)
                .then((res) => {
                    return {
                        image: res,
                        meal: meal,
                        type: type,
                    }
                })
                .catch((e) => {
                    return {
                        errors: e.response.data.errors,
                        type: type,
                        field: `mealImageEditor-${image.tempID}`,
                    }
                })
        },
        saveImages() {
            this.loading = true
            let promises = []
            this.images
                .filter((image) => !!image.meal)
                .forEach((image) => {
                    promises.push(
                        this.getPromises(image.cover, 'cover', image.meal)
                    )
                    promises.push(
                        this.getPromises(
                            image.thumbnail,
                            'thumbnail',
                            image.meal
                        )
                    )
                })
            Promise.all(promises).then((results) => {
                let errors = results.filter((result) => 'errors' in result)
                if (errors.length) {
                    this.loading = false
                    errors.forEach((o) => {
                        this.requestErrors.push({
                            field: o.field,
                            type: o.type,
                            msg: o.errors,
                        })
                    })
                } else {
                    let data = this.images
                        .filter((image) => !!image.meal)
                        .map((image) => {
                            let thumbnail = results.find(
                                (result) =>
                                    result.meal === image.meal &&
                                    result.type === 'thumbnail'
                            )
                            let cover = results.find(
                                (result) =>
                                    result.meal === image.meal &&
                                    result.type === 'cover'
                            )

                            return {
                                meal: image.meal,
                                cover: cover.image['@id'],
                                thumbnail: thumbnail.image['@id'],
                            }
                        })
                    RestApiCollection.get(this.api)
                        .assignImages(data)
                        .then(() => {
                            this.$store
                                .dispatch(
                                    `baseForm/${baseFormType.actions.LOAD}`,
                                    {
                                        api: RestApiType.RESTAURANTS,
                                        id: this.restaurant.id,
                                        mapper: MapperType.RESTAURANT_FORM,
                                    }
                                )
                                .finally(() => {
                                    Notification.success(
                                        this.$t('notifications.success'),
                                        this.$t(
                                            'notifications.entity_updated.message',
                                            { name: this.restaurant.name }
                                        )
                                    )
                                    this.loading = false
                                    this.loaded = true
                                    this.files = null
                                    this.images = null
                                })
                        })
                        .catch(() => {
                            this.loading = false
                            this.loaded = true
                            this.files = null
                        })
                }
            })
        },
    },
}
</script>

<template lang="pug">
    form-panel(:title="$t('labels.meal_images')")
        form-one-col-row
            image-upload-input(v-model="files" @input="filesSelected" :disabled="loading" :show-image="false" multiple no-upload hide-actions only-drag)
            .row(v-if='images && images.length')
                .col-12.col-md-4(v-for="(image, index) in images" :key="`mealImageEditor-${image.tempID}`")
                    meal-image-selector(
                        v-model="images[index]"
                        :disabled="loaded || loading"
                        :meals="meals"
                        :disabled-meals="selectedMeals"
                        :restaurant="restaurant['@id']"
                        :request-errors.sync="requestErrors"
                        :name="`mealImageEditor-${image.tempID}`")
        portal(v-if='images && images.length && isActive' to='footer')
            v-btn.white--text.mr-3(color='primary' :disabled='saveDisabled || loaded' :loading="loading" @click='saveImages' depressed)
                | {{ $t('actions.save') }}
            v-btn.white--text(color='error' @click='clearFiles' :disabled="loading || loaded" depressed)
                | {{ $t('actions.remove') }}

</template>
