<script>
export default {
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            id: null,
        }
    },

    mounted() {
        this.id = this._uid
    },
}
</script>

<template>
    <div class="ordit-allergen">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" class="allergen" v-on="on">
                    {{
                        $t(
                            `enums.${
                                model.type
                            }.${model.name.toLowerCase()}_short`
                        )
                    }}
                </div>
            </template>
            <span>
                {{ $t(`enums.${model.type}.${model.name.toLowerCase()}`) }}
            </span>
        </v-tooltip>
    </div>
</template>

<style lang="scss">
.ordit-allergen {
    .allergen {
        border-radius: 50%;
        font-size: 10px;
        background-color: var(--v-g-f0-base);
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
