<template>
    <div>
        <restaurant-form></restaurant-form>
    </div>
</template>

<script>
import RestaurantForm from '@/components/pages/restaurants/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        RestaurantForm,
    },
    mixins: [dirtyForm],
}
</script>
