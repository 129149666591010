<script>
import MealsInlineTable from '@/components/pages/meals/InlineTable'
import ImportMeals from '@/components/pages/meals/ImportMeals'
import ExportMeals from '@/components/pages/meals/ExportMeals'
import ClearMenu from '@/components/pages/meals/ClearMenu'

export default {
    components: {
        MealsInlineTable,
        ImportMeals,
        ExportMeals,
        ClearMenu,
    },

    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },

    methods: {
        onClearMenu() {
            this.$refs.mealsTable.refreshList()
        },
    },
}
</script>

<template>
    <!-- MENU TAB -->
    <v-tab-item
        key="menu_tab"
        :disabled="!value['@id']"
        :transition="false"
        :reverse-transition="false"
    >
        <meals-inline-table
            ref="mealsTable"
            :restaurant="value['@id']"
            @tab-error="$emit('tab-error', $event)"
        ></meals-inline-table>
        <portal v-if="!$route.query.meal && $route.query.tab == 5" to="footer">
            <import-meals class="mr-5" :restaurant="value['id']"></import-meals>
            <export-meals class="mr-2" :restaurant="value['id']"></export-meals>
            <clear-menu
                :restaurant="value['id']"
                @clear-menu="onClearMenu"
            ></clear-menu>
        </portal>
    </v-tab-item>
</template>
