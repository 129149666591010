<script>
import {
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
} from 'vuetify/lib'

export default {
    components: {
        VExpansionPanels,
        VExpansionPanel,
        VExpansionPanelContent,
        VExpansionPanelHeader,
    },

    props: {
        focusable: {
            type: Boolean,
            default: true,
        },
        panel: {
            type: Number,
            default: null,
        },
        eager: {
            type: Boolean,
        },
    },

    data() {
        return {
            show: true,
        }
    },

    watch: {
        '$slots.default': {
            handler() {
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            },
        },
    },

    render(h) {
        return h(
            'v-expansion-panels',
            {
                props: {
                    value: this.panel,
                    accordion: true,
                    eager: this.eager,
                },
            },
            [
                this.$slots.default && this.show
                    ? this.$slots.default.map(
                          function (el) {
                              return h('v-expansion-panel', {}, [
                                  h('v-expansion-panel-header', [
                                      el.data.attrs.name,
                                  ]),
                                  h('v-expansion-panel-content', [el]),
                              ])
                          }.bind(this)
                      )
                    : null,
            ]
        )
    },
}
</script>
