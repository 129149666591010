<script>
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import MultiCutoffFormPart from '@/components/pages/canteen/MultiCutoffFormPart'

import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'
import { defaultCutoff } from '@/components/pages/canteen/MultiCutoffFormPart'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        DatePickerInput,
        MultiCutoffFormPart,
    },
    inject: {
        $validator: 'formValidator',
    },
    mixins: [FormTabMixin, FormInput],
    computed: {
        defaultCutoff() {
            return defaultCutoff
        },
    },
}
</script>

<template>
    <!-- CONTACT SUBTAB -->
    <v-tab-item
        key="info_group_orders_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel :title="$t('labels.canteen_order_limits')">
            <!-- defaultCanteenRules -->
            <multi-cutoff-form-part
                v-model="model.defaultCanteenRules"
                :new-rule="{
                    cutoffTime: defaultCutoff,
                    limit: 999,
                }"
                :reference-date="defaultCutoff"
                hide-date
            ></multi-cutoff-form-part>
        </form-panel>

        <form-panel :title="$t('labels.canteen_small_order_limit')">
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- canteenSmallOrderLimit -->
                    <text-input
                        v-model="model.canteenSmallOrderLimit"
                        v-validate.immediate="'numeric|min_value:0'"
                        v-tab-error:groupOrders
                        type="number"
                        name="canteenSmallOrderLimit"
                        label="labels.canteen_small_order_limit"
                        :error-messages="
                            errors.collect('canteenSmallOrderLimit')
                        "
                        :data-vv-as="$t('labels.canteen_small_order_limit')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <form-panel :title="$t('labels.canteen_small_order_settings')">
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- canteenSmallEarliestPickup -->
                    <date-picker-input
                        v-model="model.canteenSmallEarliestPickup"
                        v-validate="'date_format:HH:mm'"
                        v-tab-error:groupOrders
                        name="canteenSmallEarliestPickup"
                        label="labels.canteen_earliest_pickup"
                        :error-messages="
                            errors.collect('canteenSmallEarliestPickup')
                        "
                        :data-vv-as="$t('labels.canteen_earliest_pickup')"
                        only-time
                    ></date-picker-input>
                </template>
                <template v-slot:col2>
                    <!-- canteenSmallLatestPickup -->
                    <date-picker-input
                        v-model="model.canteenSmallLatestPickup"
                        v-validate="'date_format:HH:mm'"
                        v-tab-error:groupOrders
                        name="canteenSmallLatestPickup"
                        label="labels.canteen_latest_pickup"
                        :error-messages="
                            errors.collect('canteenSmallLatestPickup')
                        "
                        :data-vv-as="$t('labels.canteen_latest_pickup')"
                        only-time
                    ></date-picker-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- canteenSmallLatestCutoff -->
                    <date-picker-input
                        v-model="model.canteenSmallLatestCutoff"
                        v-validate="'date_format:HH:mm'"
                        v-tab-error:groupOrders
                        name="canteenSmallLatestCutoff"
                        label="labels.canteen_latest_cutoff"
                        :error-messages="
                            errors.collect('canteenSmallLatestCutoff')
                        "
                        :data-vv-as="$t('labels.canteen_latest_cutoff')"
                        only-time
                    ></date-picker-input>
                </template>
                <template v-slot:col2>
                    <!-- canteenSmallCutoffNotice -->
                    <text-input
                        v-model="model.canteenSmallCutoffNotice"
                        v-validate.immediate="'numeric|min_value:0'"
                        v-tab-error:groupOrders
                        type="number"
                        name="canteenSmallCutoffNotice"
                        label="labels.canteen_cutoff_notice"
                        :error-messages="
                            errors.collect('canteenSmallCutoffNotice')
                        "
                        :data-vv-as="$t('labels.canteen_cutoff_notice')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <form-panel
            :title="$t('labels.canteen_large_order_settings')"
            class="mb-16"
        >
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- canteenLargeEarliestPickup -->
                    <date-picker-input
                        v-model="model.canteenLargeEarliestPickup"
                        v-validate="'date_format:HH:mm'"
                        v-tab-error:groupOrders
                        name="canteenLargeEarliestPickup"
                        label="labels.canteen_earliest_pickup"
                        :error-messages="
                            errors.collect('canteenLargeEarliestPickup')
                        "
                        :data-vv-as="$t('labels.canteen_earliest_pickup')"
                        only-time
                    ></date-picker-input>
                </template>
                <template v-slot:col2>
                    <!-- canteenLargeLatestPickup -->
                    <date-picker-input
                        v-model="model.canteenLargeLatestPickup"
                        v-validate="'date_format:HH:mm'"
                        v-tab-error:groupOrders
                        name="canteenLargeLatestPickup"
                        label="labels.canteen_latest_pickup"
                        :error-messages="
                            errors.collect('canteenLargeLatestPickup')
                        "
                        :data-vv-as="$t('labels.canteen_latest_pickup')"
                        only-time
                    ></date-picker-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- canteenLargeLatestCutoff -->
                    <date-picker-input
                        v-model="model.canteenLargeLatestCutoff"
                        v-validate="'date_format:HH:mm'"
                        v-tab-error:groupOrders
                        name="canteenLargeLatestCutoff"
                        label="labels.canteen_latest_cutoff"
                        :error-messages="
                            errors.collect('canteenLargeLatestCutoff')
                        "
                        :data-vv-as="$t('labels.canteen_latest_cutoff')"
                        only-time
                    ></date-picker-input>
                </template>
                <template v-slot:col2>
                    <!-- canteenLargeCutoffNotice -->
                    <text-input
                        v-model="model.canteenLargeCutoffNotice"
                        v-validate.immediate="'numeric|min_value:0'"
                        v-tab-error:groupOrders
                        type="number"
                        name="canteenLargeCutoffNotice"
                        label="labels.canteen_cutoff_notice"
                        :error-messages="
                            errors.collect('canteenLargeCutoffNotice')
                        "
                        :data-vv-as="$t('labels.canteen_cutoff_notice')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
    </v-tab-item>
</template>
