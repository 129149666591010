<script>
import FormPartMixin from '@/components/mixins/FormPartMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import SoftwareLimits from '@/components/pages/restaurants/SoftwareLimits'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        CheckboxInput,
        SoftwareLimits,
    },

    mixins: [FormPartMixin, FormTabMixin],

    props: {
        isDefault: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        propKey() {
            return this.isDefault ? 'orditSoftwareSetting' : 'softwareSetting'
        },
        isAps() {
            return this.form?.software === 'aps-callback'
        },
    },
    methods: {
        addRule() {
            this.form.extraData.push({
                rule: null,
                value: null,
            })
        },
    },
}
</script>

<template>
    <div class="restaurant-software">
        <form-panel :title="$t('labels.order_transfer_rules')">
            <software-limits
                v-model="form"
                :name="form.software + 'software-setting'"
                @tabError="tabErrorsContainer = $event"
            ></software-limits>
        </form-panel>
        <form-panel v-if="!isAps" :title="$t('labels.identification')">
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- ID 1 -->
                    <text-input
                        v-model="form.username"
                        v-validate="'space'"
                        v-tab-error:orderTransfer
                        :name="`${propKey}.username`"
                        label="labels.id_1"
                        :error-messages="errors.collect(`${propKey}.username`)"
                        :data-vv-as="$t('labels.id_1')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- ID 2 -->
                    <text-input
                        v-model="form.password"
                        v-validate="''"
                        v-tab-error:orderTransfer
                        :name="`${propKey}.password`"
                        label="labels.id_2"
                        :error-messages="errors.collect(`${propKey}.password`)"
                        :data-vv-as="$t('labels.id_2')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <form-panel v-else :title="$t('labels.callback_rules')">
            <p>
                <v-btn color="info" small @click="addRule">
                    <v-icon left>add</v-icon> {{ $t('actions.add') }}
                </v-btn>
            </p>
            <v-row v-for="(rule, index) in form.extraData" :key="index">
                <v-col cols="6" class="pb-0 pt-2">
                    <v-text-field
                        v-if="index === 0"
                        label="labels.rule"
                        :value="$t('labels.default')"
                        disabled
                    ></v-text-field>
                    <text-input
                        v-else
                        v-model="rule.rule"
                        v-validate.immediate="'required'"
                        label="labels.rule"
                        :name="`rule[${index}]`"
                        :error-messages="errors.collect(`rule[${index}]`)"
                    ></text-input>
                </v-col>
                <v-col cols="5" class="pb-0 pt-2">
                    <text-input
                        v-model="rule.value"
                        label="labels.callback_url"
                        :name="`callback[${index}]`"
                        :data-vv-as="$t('labels.cc_cutoff.date')"
                        :error-messages="errors.collect(`callback[${index}]`)"
                    ></text-input>
                </v-col>
                <v-col cols="1" class="pb-0 pt-3">
                    <v-btn
                        v-if="index > 0"
                        color="error"
                        icon
                        @click="form.extraData.splice(index, 1)"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </form-panel>
        <form-panel :title="$t('labels.time_settings')">
            <form-two-col-row>
                <!-- OPENING TIME CHECK -->
                <template v-slot:col1>
                    <checkbox-input
                        v-model="form.isOpeningTimeChecked"
                        v-validate="''"
                        v-tab-error:orderTransfer
                        label="texts.order_transfer_should_recognize_the_opening_time_of_the_restaurant"
                        name="isPreorderDiffCalendar"
                    ></checkbox-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- OPENING DELAY -->
                    <text-input
                        v-model.number="form.openingDelay"
                        v-validate="'integer'"
                        v-tab-error:orderTransfer
                        :name="`${propKey}.openingDelay`"
                        label="texts.order_transfer_open_time_delay"
                        :error-messages="
                            errors.collect(`${propKey}.openingDelay`)
                        "
                        :data-vv-as="$t('texts.order_transfer_open_time_delay')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- CLOSING DELAY -->
                    <text-input
                        v-model.number="form.closingDelay"
                        v-validate="'integer'"
                        v-tab-error:orderTransfer
                        :name="`${propKey}.closingDelay`"
                        label="texts.order_transfer_close_time_delay"
                        :error-messages="
                            errors.collect(`${propKey}.closingDelay`)
                        "
                        :data-vv-as="
                            $t('texts.order_transfer_close_time_delay')
                        "
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <div v-if="!isDefault" class="d-flex justify-end">
            <v-btn
                class="ma-2"
                outlined
                color="error"
                @click="$emit('remove-software', form)"
            >
                <v-icon left>remove</v-icon> {{ $t('actions.remove') }}
            </v-btn>
        </div>
    </div>
</template>
