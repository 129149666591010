<script>
import MealOptionGroupForm from '@/components/pages/meals/mealOptions/MealOptionGroupForm'

import mealType from '@/store/type/mealType'
import { mapGetters } from 'vuex'

export default {
    components: {
        MealOptionGroupForm,
    },

    data() {
        return {
            model: {},
            show: false,
        }
    },

    computed: {
        ...mapGetters({
            mealOptionGroup: mealType.getters.MEAL_OPTION_GROUP,
        }),
    },

    watch: {
        mealOptionGroup: {
            handler() {
                this.model = this.mealOptionGroup
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        toggleShow() {
            this.show = !this.show

            if (!this.show) this.$emit('tab-error', {})
        },
    },
}
</script>

<template>
    <div class="edit-meal-option-group">
        <v-btn @click="toggleShow">
            <v-icon left>edit</v-icon>
            {{ $t('labels.edit_meal_option_group') }}
        </v-btn>
        <meal-option-group-form
            v-if="show"
            v-model="model"
            @cancel="toggleShow"
            @saved="toggleShow"
            @tabError="$emit('tab-error', $event)"
        ></meal-option-group-form>
    </div>
</template>
