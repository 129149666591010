<script>
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'

export default {
    components: {
        TextInput,
        CheckboxInput,
        DatePickerInput,
    },

    mixins: [FormInput, FormTabMixin],

    props: {
        showDelete: Boolean,
    },

    watch: {
        'model.deliveryMinTime'(val) {
            if (val == '') this.$set(this.model, 'deliveryMinTime', 0)
        },
        'model.deliveryMaxTime'(val) {
            if (val == '') this.$set(this.model, 'deliveryMaxTime', 0)
        },
        'model.minimumOrder'(val) {
            if (val == '') this.$set(this.model, 'minimumOrder', '0')
        },
        'model.deliveryPrice'(val) {
            if (val == '') this.$set(this.model, 'deliveryPrice', '0')
        },
        'model.isPreOrder'(val) {
            if (!val) {
                this.$set(this.model, 'preOrderBorderTime', null)
                this.$set(this.model, 'preOrderDayBefore', null)
            }
        },
    },

    methods: {
        deleteVariant() {
            this.$emit('remove-variant')
        },
    },
}
</script>

<template>
    <div>
        {{ $t('texts.delivery_area_limits_part_1') }}
        <text-input
            v-model="model.minimumOrder"
            v-tab-error:deliveryZones
            v-validate="'space'"
            class="input-field"
            name="minimumOrder"
            label="labels.minimum_order"
            :error-messages="errors.collect('minimumOrder')"
            :data-vv-as="$t('labels.minimum_order')"
        ></text-input>
        {{ $t('texts.delivery_area_limits_part_2') }}
        <text-input
            v-model="model.deliveryPrice"
            v-validate="'space'"
            v-tab-error:deliveryZones
            class="input-field"
            name="deliveryPrice"
            label="labels.delivery_price"
            :error-messages="errors.collect('delivery_price')"
            :data-vv-as="$t('labels.delivery_price')"
        ></text-input>
        {{ $t('texts.delivery_area_limits_part_3') }}
        <text-input
            v-model="model.deliveryMinTime"
            v-tab-error:deliveryZones
            v-validate.immediate="'required|integer'"
            type="number"
            class="input-field"
            name="deliveryMinTime"
            label="labels.delivery_min_time"
            :error-messages="errors.collect('deliveryMinTime')"
            :data-vv-as="$t('labels.delivery_min_time')"
        ></text-input>
        -
        <text-input
            v-model="model.deliveryMaxTime"
            v-tab-error:deliveryZones
            v-validate.immediate="'required|integer'"
            type="number"
            class="input-field"
            name="deliveryMaxTime"
            label="labels.delivery_max_time"
            :error-messages="errors.collect('deliveryMaxTime')"
            :data-vv-as="$t('labels.delivery_max_time')"
        ></text-input>

        {{ $t('texts.delivery_area_limits_part_4') }}
        <checkbox-input
            v-model="model.isPreOrder"
            class="input-field"
            name="isPreorder"
            label="texts.can_only_preorder"
        ></checkbox-input>

        <div v-if="model.isPreOrder" class="d-inline">
            <date-picker-input
                v-model="model.preOrderBorderTime"
                v-tab-error:deliveryZones
                v-validate.immediate="''"
                class="input-field preorder"
                name="preOrderBorderTime"
                label="texts.until_when_is_it_possible_to_submit_an_order"
                :error-messages="errors.collect('preOrderBorderTime')"
                :data-vv-as="
                    $t('texts.until_when_is_it_possible_to_submit_an_order')
                "
                only-time
            ></date-picker-input>
            -
            <text-input
                v-model.number="model.preOrderDayBefore"
                v-tab-error:deliveryZones
                v-validate.immediate="'integer|min_value:0'"
                class="input-field preorder"
                name="preOrderDayBefore"
                label="labels.notice_period"
                :error-messages="errors.collect('preOrderDayBefore')"
                :data-vv-as="$t('labels.notice_period')"
                type="number"
            ></text-input>
        </div>
        <v-btn v-if="showDelete" icon color="warning" @click="deleteVariant">
            <v-icon>delete</v-icon>
        </v-btn>
    </div>
</template>

<style>
.input-field {
    display: inline-block;
    width: 80px;
    margin-right: 10px;
    margin-left: 10px;
}
.input-field.v-input--switch {
    width: 220px;
}
.input-field.preorder {
    width: 120px;
}
</style>
