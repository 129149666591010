<script>
import MealCategoryForm from '@/components/pages/meals/mealCategory/MealCategoryForm'

import mealType from '@/store/type/mealType'
import { mapGetters } from 'vuex'

export default {
    components: {
        MealCategoryForm,
    },

    data() {
        return {
            model: {},
            show: false,
        }
    },

    computed: {
        ...mapGetters({
            mealCategory: mealType.getters.MEAL_CATEGORY,
        }),
    },

    watch: {
        mealCategory: {
            handler() {
                this.model = this.mealCategory
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        toggleShow() {
            this.show = !this.show
        },
    },
}
</script>

<template>
    <div class="edit-meal-category">
        <v-btn @click="toggleShow">
            <v-icon left>edit</v-icon>
            {{ $t('labels.edit_meal_category') }}
        </v-btn>
        <meal-category-form
            v-if="show"
            v-model="model"
            @saved="toggleShow"
            @cancel="toggleShow"
        ></meal-category-form>
    </div>
</template>
