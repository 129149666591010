<script>
import FormPanel from '@/components/form/FormPanel'
import FormOneColRow from '@/components/form/FormOneColRow'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'

import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

import DeliveryRules from '@/components/pages/restaurants/DeliveryRules'
import AddDeliveryRule from '@/components/pages/restaurants/AddDeliveryRule'
import TextInput from '@/components/form/Inputs/TextInput'

export default {
    components: {
        FormPanel,
        FormOneColRow,
        CheckboxInput,
        DeliveryRules,
        AddDeliveryRule,
        TextInput,
    },

    mixins: [PhoneNumberMixin, FormTabMixin, FormInput],

    inject: {
        $validator: 'formValidator',
    },

    methods: {
        addDeliveryRule(newRule) {
            this.model.deliverySetting.deliveryRules = this.model
                .deliverySetting.deliveryRules?.length
                ? [...this.model.deliverySetting.deliveryRules, newRule]
                : [newRule]
        },
    },
}
</script>

<template>
    <!-- DELIVERY RULES TAB -->
    <v-tab-item
        key="delivery_rules_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel :title="$t('labels.delivery_rules')">
            <form-one-col-row v-if="model.deliverySetting" class="mb-4">
                <checkbox-input
                    v-model="model.deliverySetting.orditManagedDelivery"
                    v-validate="{}"
                    v-tab-error:deliveryRules
                    name="deliverySetting.orditManagedDelivery"
                    label="labels.delivery_rules_label"
                    :error-messages="
                        errors.collect('deliverySetting.orditManagedDelivery')
                    "
                    :data-vv-as="$t('labels.delivery_rules_label')"
                ></checkbox-input>
            </form-one-col-row>

            <form-one-col-row>
                <delivery-rules
                    v-if="model.deliverySetting"
                    v-model="model.deliverySetting.deliveryRules"
                    :enabled="model.deliverySetting.orditManagedDelivery"
                    @added="addDeliveryRule"
                    @tabError="$emit('tabError', $event)"
                ></delivery-rules>
            </form-one-col-row>
        </form-panel>
        <add-delivery-rule @added="addDeliveryRule"></add-delivery-rule>
        <form-panel :title="$t('labels.order_grouping_settings')">
            <form-one-col-row v-if="model.deliverySetting" class="mb-4">
                <checkbox-input
                    v-model="model.deliverySetting.orderGroupingEnabled"
                    v-validate="{}"
                    v-tab-error:deliveryRules
                    name="deliverySetting.orderGroupingEnabled"
                    label="labels.order_grouping_label"
                    :error-messages="
                        errors.collect('deliverySetting.orderGroupingEnabled')
                    "
                    :data-vv-as="$t('labels.order_grouping_label')"
                ></checkbox-input>
            </form-one-col-row>

            <form-one-col-row v-if="model.deliverySetting">
                <div class="row">
                    <div class="col-md-4">
                        <text-input
                            v-model="
                                model.deliverySetting.orderGroupingTimeBefore
                            "
                            v-validate.immediate="'integer|min_value:1'"
                            v-tab-error:deliveryRules
                            type="number"
                            label="labels.order_grouping_time_before"
                            name="deliverySetting.orderGroupingTimeBefore"
                            :error-messages="
                                errors.collect(
                                    'deliverySetting.orderGroupingTimeBefore'
                                )
                            "
                            :data-vv-as="
                                $t('labels.order_grouping_time_before')
                            "
                            :disabled="
                                !model.deliverySetting.orderGroupingEnabled
                            "
                        ></text-input>
                    </div>
                    <div class="col-md-4">
                        <text-input
                            v-model="
                                model.deliverySetting.orderGroupingTimeAfter
                            "
                            v-validate.immediate="'integer|min_value:1'"
                            v-tab-error:deliveryRules
                            type="number"
                            label="labels.order_grouping_time_after"
                            name="deliverySetting.orderGroupingTimeAfter"
                            :error-messages="
                                errors.collect(
                                    'deliverySetting.orderGroupingTimeAfter'
                                )
                            "
                            :data-vv-as="$t('labels.order_grouping_time_after')"
                            :disabled="
                                !model.deliverySetting.orderGroupingEnabled
                            "
                        ></text-input>
                    </div>
                </div>
            </form-one-col-row>
        </form-panel>
    </v-tab-item>
</template>
