<template>
    <div>
        <v-data-table
            :items="rows"
            :headers="headers"
            disable-filtering
            disable-pagination
            hide-default-footer
            must-sort
            :sort-by="allowSorting ? 'date' : undefined"
            :sort-desc="allowSorting"
            :no-data-text="$t('labels.no_data_available')"
        >
            <template v-slot:[`item.hours`]="{ item }">
                <template v-if="item.hours.length">
                    <v-chip
                        v-for="(hour, index) in item.hours"
                        :key="index"
                        style="margin-right: 5px"
                    >
                        {{ hour }}
                    </v-chip>
                </template>
                <template v-else>
                    {{ $t('labels.closed_all_day') }}
                </template>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <table-button
                    :tooltip="$t('actions.delete')"
                    color="blue-grey"
                    icon="delete"
                    @execute="remove(item.index)"
                ></table-button>
            </template>
        </v-data-table>
        <confirm-modal
            v-model="dialog"
            :title="$t('texts.are_you_sure')"
            :approve-text="$t('actions.delete')"
            :cancel-text="$t('actions.cancel')"
            @onApprove="confim"
            @onCancel=";(indexToRemove = null), (dialog = false)"
        ></confirm-modal>
    </div>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import TableButton from '@/components/table/buttons/TableButton'
import ConfirmModal from '@/components/confirm/ConfirmModal'

export default {
    components: {
        TableButton,
        ConfirmModal,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            indexToRemove: null,
        }
    },
    computed: {
        ...mapGetters({
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
        headers() {
            return [
                {
                    text: this.$t('labels.date'),
                    value: 'date',
                },
                {
                    text: this.$t('labels.special_hours'),
                    value: 'hours',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false,
                },
            ]
        },
        rows() {
            const rows = []
            this.value.forEach((closureDate, dateIndex) => {
                const hours = []

                if (closureDate['timeIntervals']) {
                    closureDate['timeIntervals'].forEach((closureHour) => {
                        hours.push(
                            closureHour.openTime + ' - ' + closureHour.closeTime
                        )
                    })
                }

                rows.push({
                    date: closureDate.date,
                    hours,
                    index: dateIndex,
                })
            })

            return rows
        },
        allowSorting() {
            return Boolean(this.rows && this.rows.length > 1)
        },
    },
    methods: {
        remove(index) {
            if (this.isRestaurantAdmin) {
                this.indexToRemove = index
                this.dialog = true
                return
            } else {
                const value = CloneDeep(this.value)

                value.splice(index, 1)

                this.$emit('input', value)
            }
        },
        confim() {
            if (this.indexToRemove === null) return

            const value = CloneDeep(this.value)

            value.splice(this.indexToRemove, 1)

            this.$emit('input', value)
            this.dialog = false
            this.indexToRemove = null
        },
    },
}
</script>
