<script>
import FormTabs from '@/components/form/FormTabs'
import FormTab from '@/components/form/FormTab'
import Contact from './info/Contact'
import GroupOrders from './info/GroupOrders'
import Contractual from './info/Contractual'
import PrintSettings from './info/PrintSettings'
import FormTabMixin from '@/components/mixins/FormTabMixin'

export default {
    components: {
        FormTabs,
        FormTab,
        Contact,
        GroupOrders,
        Contractual,
        PrintSettings,
    },
    mixins: [FormTabMixin],
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        tabs() {
            return [
                {
                    name: 'contact',
                    title: this.$t('labels.contact'),
                },
                {
                    name: 'groupOrders',
                    title: this.$t('labels.group_orders'),
                },
                {
                    name: 'printSettings',
                    title: this.$t('labels.print_settings'),
                },
                {
                    name: 'contractual',
                    title: this.$t('labels.contractual'),
                },
            ]
        },
        activeTab: {
            get() {
                return parseInt(this.$route.query.infoTab) || 0
            },
            set(val) {
                this.$router.replace({
                    query: { infoTab: val, tab: this.$route.query.tab },
                })
            },
        },
        tabErrors() {
            const tabErrors = {}
            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                Object.keys(this.tabErrorsContainer[tabName]).forEach(
                    (propertyName) => {
                        if (!tabErrors[tabName]) {
                            tabErrors[tabName] = 0
                        }
                        if (
                            this.tabErrorsContainer[tabName][propertyName] > 0
                        ) {
                            tabErrors[tabName]++
                        }
                    }
                )
            })
            return tabErrors
        },
    },

    methods: {
        getTabError(val) {
            this.tabErrorsContainer = {
                ...this.tabErrorsContainer,
                ...val,
            }

            let subTabErrors = {}

            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                subTabErrors = {
                    ...subTabErrors,
                    ...this.tabErrorsContainer[tabName],
                }
            })

            this.$emit('tabError', {
                info: subTabErrors,
            })
        },
        onInput(val) {
            this.$emit('input', val)
        },
    },
}
</script>

<template>
    <v-tab-item
        key="info_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-tabs v-model="activeTab" class="pb-16">
            <form-tab
                v-for="tab in tabs"
                :key="tab.name"
                :tab-errors="tabErrors"
                :name="tab.name"
                :title="tab.title"
                :disabled="tab.disabled ? true : false"
            ></form-tab>

            <v-tabs-items v-model="activeTab">
                <contact
                    :value="value"
                    @input="onInput"
                    @tabError="getTabError"
                ></contact>
                <group-orders
                    :value="value"
                    @input="onInput"
                    @tabError="getTabError"
                ></group-orders>
                <print-settings
                    :value="value"
                    @input="onInput"
                    @tabError="getTabError"
                ></print-settings>
                <contractual
                    :value="value"
                    @input="onInput"
                    @tabError="getTabError"
                ></contractual>
            </v-tabs-items>
        </form-tabs>
    </v-tab-item>
</template>
