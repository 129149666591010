<template>
    <div>
        <form-two-col-row>
            <template v-slot:col1>
                <form-two-col-row>
                    <template v-slot:col1>
                        <div class="d-inline-block pr-5">
                            <date-picker-input
                                v-model="startDate"
                                v-validate.disable="'required'"
                                label="labels.start_date"
                                style="width: 200px"
                                :name="name + '_start_date'"
                                :error-messages="
                                    errors.collect(name + '_start_date')
                                "
                                :data-vv-as="$t('labels.start_date')"
                                :min="today"
                                :max="endDate"
                                data-vv-validate-on="custom"
                                only-date
                            ></date-picker-input>
                        </div>
                    </template>
                    <template v-slot:col2>
                        <div class="d-inline-block">
                            <date-picker-input
                                v-model="endDate"
                                v-validate.disable="'required'"
                                label="labels.end_date"
                                style="width: 200px"
                                :name="name + '_end_date'"
                                :error-messages="
                                    errors.collect(name + '_end_date')
                                "
                                :data-vv-as="$t('labels.end_date')"
                                :min="startDate ? startDate : today"
                                only-date
                            ></date-picker-input>
                        </div>
                    </template>
                </form-two-col-row>
                <checkbox-input
                    v-model="wholeDay"
                    class="d-inline-block"
                    label="labels.whole_day"
                ></checkbox-input>
            </template>
            <template v-slot:col2>
                <v-card v-if="hourPeriods.length" class="day-card">
                    <v-card-title>
                        <h4>{{ $t('labels.period') }}</h4>
                    </v-card-title>
                    <v-card-text>
                        <template>
                            <div
                                v-for="(hourPeriod, index) in hourPeriods"
                                :key="index"
                                class="mb-2"
                            >
                                <hour-period-input
                                    v-validate="{
                                        'period-hour-format': {},
                                        'period-from-lt-to': {},
                                        'period-overlap': {
                                            periods: hourPeriods,
                                            index,
                                        },
                                    }"
                                    :value="hourPeriod"
                                    :values="hourPeriods"
                                    :period-index="index"
                                    :name="name + '_hour_period_' + index"
                                    :error-messages="
                                        errors.collect(
                                            name + '_hour_period_' + index
                                        )
                                    "
                                    class="d-inline-block day-hour-period"
                                    @input="setHourPeriod(index, $event)"
                                ></hour-period-input>
                                <v-btn
                                    text
                                    icon
                                    color="grey darken-3"
                                    class="ma-0"
                                    @click="removeHour(index)"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </div>
                            <v-btn
                                v-if="hourPeriods.length"
                                icon
                                color="green"
                                class="period-add-btn"
                                @click="addHour"
                            >
                                <v-icon>add</v-icon>
                            </v-btn>
                        </template>
                    </v-card-text>
                </v-card>
            </template>
        </form-two-col-row>

        <form-one-col-row>
            <v-btn color="primary" style="margin-right: 10px" @click="add">
                {{ $t('labels.add') }}
            </v-btn>
            <v-btn @click="reset">
                {{ $t('labels.reset') }}
            </v-btn>
        </form-one-col-row>
    </div>
</template>

<script>
import FormOneColRow from '@/components/form/FormOneColRow'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import CloneDeep from 'lodash/cloneDeep'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import HourPeriodInput from '@/components/form/Inputs/HourPeriodInput'

export default {
    components: {
        HourPeriodInput,
        FormTwoColRow,
        CheckboxInput,
        FormOneColRow,
        DatePickerInput,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            wholeDay: true,
            hourPeriods: [],
        }
    },
    computed: {
        today() {
            return this.$date().format('YYYY-MM-DD')
        },
    },
    watch: {
        wholeDay(wholeDay) {
            this.hourPeriods = wholeDay ? [] : [{ from: '08:00', to: '16:00' }]
        },
        startDate(startDate) {
            if (this.endDate === null) {
                this.endDate = startDate
            }
        },
    },
    methods: {
        validate() {
            const existingDays = this.value.reduce((dates, day) => {
                dates.push(day.date)
                return dates
            }, [])
            const period = this.datePeriod()
            const intersection = period.filter((date) =>
                existingDays.includes(date)
            )

            if (intersection.length > 0) {
                this.addError(
                    this.name + '_start_date',
                    this.$t('validations.messages.date-period-overlap')
                )

                return false
            }

            if (this.startDate > this.endDate) {
                this.addError(
                    this.name + '_start_date',
                    this.$t('validations.messages.period-from-lt-to')
                )

                return false
            }

            return true
        },
        add() {
            this.$validator.validateAll().then((valid) => {
                valid = valid && this.validate()

                if (valid) {
                    const value = CloneDeep(this.value)
                    const period = this.datePeriod()

                    period.forEach((date) => {
                        value.push({
                            date: date,
                            day: this.dayOfTheWeek(date),
                            timeIntervals: this.getTimeIntervals(),
                        })
                    })

                    this.$emit('input', value)
                    this.reset()
                }
            })
        },
        addError(key, error) {
            const field = this.$validator.fields.find({
                name: key,
                scope: this.$options.scope,
            })

            if (!field) return

            this.$validator.errors.add({
                id: field.id,
                field: key,
                msg: error,
                scope: this.$options.scope,
            })

            field.setFlags({
                invalid: true,
                valid: false,
                validated: true,
            })
        },
        addHour() {
            if (this.hourPeriods.length) {
                this.hourPeriods.push({
                    from: this.hourPeriods[this.hourPeriods.length - 1].to,
                    to: this.hourPeriods[this.hourPeriods.length - 1].to,
                })
            } else {
                this.hourPeriods.push({ from: '08:00', to: '16:00' })
            }
        },
        reset() {
            this.startDate = null
            this.endDate = null
            this.wholeDay = true
            this.hourPeriods = []
            this.errors.clear()
        },
        dayOfTheWeek(dateString) {
            const date = new Date(dateString)
            let dayOfTheWeek = date.getDay()

            if (dayOfTheWeek === 0) {
                dayOfTheWeek = 7
            }

            return dayOfTheWeek
        },
        datePeriod() {
            const period = []

            let endDate = new Date(this.endDate)
            endDate.setHours(0, 0, 0)

            let loop = new Date(this.startDate)
            loop.setHours(0, 0, 0)

            while (loop <= endDate) {
                period.push(this.$date(loop).format('YYYY-MM-DD'))
                loop.setDate(loop.getDate() + 1)
            }

            return period
        },
        setHourPeriod(index, value) {
            const hourPeriods = CloneDeep(this.hourPeriods)

            hourPeriods[index] = value

            this.hourPeriods = hourPeriods
        },
        getTimeIntervals() {
            const intervals = []

            this.hourPeriods.forEach((period) => {
                intervals.push({
                    openTime: period.from,
                    closeTime: period.to,
                })
            })

            return intervals
        },
        removeHour(index) {
            const hourPeriods = CloneDeep(this.hourPeriods)

            hourPeriods.splice(index, 1)

            this.hourPeriods = hourPeriods

            if (this.hourPeriods.length === 0) {
                this.wholeDay = true
            }
        },
    },
}
</script>
