<template>
    <!-- CONTACT SUBTAB -->
    <v-tab-item
        key="info_printing_settings_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel :title="$t('labels.restaurant_print_config')">
            <v-row>
                <v-col v-for="conf in configs" :key="conf.id" cols="6">
                    <checkbox-input
                        v-model="model.printConfig[conf.key]"
                        :label="conf.label"
                        :name="conf.name"
                        hide-details
                    ></checkbox-input>
                </v-col>
            </v-row>
        </form-panel>
        <form-panel :title="$t('labels.restaurant_canteen_print_config')">
            <v-row>
                <v-col v-for="conf in canteenConfigs" :key="conf.id" cols="6">
                    <checkbox-input
                        v-model="model.canteenPrintConfig[conf.key]"
                        :label="conf.label"
                        :name="conf.name"
                        hide-details
                    ></checkbox-input>
                </v-col>
            </v-row>
        </form-panel>
    </v-tab-item>
</template>

<script>
import selectapiType from '@/store/type/selectapiType'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

import FormPanel from '@/components/form/FormPanel'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'

export default {
    components: {
        FormPanel,
        CheckboxInput,
    },
    inject: {
        $validator: 'formValidator',
    },
    mixins: [FormTabMixin, FormInput],
    data() {
        return {
            configs: [],
            canteenConfigs: [],
        }
    },
    mounted() {
        this.$store
            .dispatch(
                selectapiType.getActionName(
                    selectapiType.clientTypes.RESTAURANT_PRINT_CONFIG,
                    selectapiType.actions.LOAD
                )
            )
            .then((res) => (this.configs = res))
        this.$store
            .dispatch(
                selectapiType.getActionName(
                    selectapiType.clientTypes.RESTAURANT_CANTEEN_PRINT_CONFIG,
                    selectapiType.actions.LOAD
                )
            )
            .then((res) => (this.canteenConfigs = res))
    },
}
</script>
